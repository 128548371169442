import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../../../commn/Navbar";
import Wrapper from "../../../style/wrappers/RegisterPage";

export const BlogsPdfAddToBlogs = () => {
  const [uname, setUname] = useState("");
  const [udate, setUdate] = useState("");
  const [sdesc, setSdesc] = useState("");
  const [blogname, setBlogname] = useState("");
  const [url, setUrl] = useState("");

  const [name, setName] = useState("");
  const [uploadername, setUploadername] = useState("");
  const [date, setDate] = useState("");
  const [shortdesc, setShortdesc] = useState("");
  const [longdesc, setLongdesc] = useState("");
  const [file, setFile] = useState([]);

  const navi = new useNavigate();

  const params = useParams();
  const id = params.id;

  // console.log(id);
  useEffect(() => {
    axios.get(`/blogspdf/get/${id}`).then((res) => {
      setUname(res.data.uploadername);
      setUdate(res.data.date);
      setSdesc(res.data.shortdesc);
      setBlogname(res.data.name);
      setUrl(res.data.image);
    });
  });

  const ImageAdd = (e) => {
    setFile(e.target.files[0]);
  };

  const Submit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", blogname);
    formData.append("uploadername", uname);
    formData.append("date", udate);
    formData.append("shortdesc", sdesc);
    formData.append("longdesc", url);
    formData.append("blogstype", "pdf");
    formData.append("add", "add");
    formData.append("file", file);

    setName("");
    setUploadername("");
    setDate("");
    setShortdesc("");
    setLongdesc("");

    const add = {
      add: "add",
    };
    console.log(formData);
    console.log(name);
    console.log(formData);

    axios.post("/blogs/add", formData).then((res) => {
      axios.put(`/blogspdf/update/${id}`, add);
      alert("Succsessfully Added Blogs");
      navi("/Ablogsdisplay");
    });
  };

  return (
    <div>
      <div>
        <Navbar />
        <div className="" style={{ textAlign: "center", marginTop: "5%" }}>
          <Link to={"/Ablogsdisplay"}>
            <button type="button" className="btn btn-primary">
              Blogs
            </button>
          </Link>
          <Link to={"/Ablogs"}>
            <button type="button" className="btn btn-primary">
              ADD New Blogs
            </button>
          </Link>
        </div>
        <Wrapper className="full-page">
          <form
            className="form"
            encType="multipart/form-data"
            onSubmit={Submit}
          >
            <h3>Add New BlogPDF to Blogs</h3>

            {/*  */}
            <div className="form-row">
              <label className="form-label">Uploader Name</label>
              <input
                type="text"
                name={uname}
                value={JSON.parse(localStorage.getItem("user")).name}
                onChange={(e) => setUploadername(e.target.value)}
                className="form-input"
                disabled
              />
            </div>
            {/* date */}
            <div className="form-row">
              <label className="form-label">Date</label>
              <input
                type="text"
                value={udate}
                name={udate}
                onChange={(e) => setDate(e.target.value)}
                className="form-input"
                disabled
              />
            </div>
            {/*Blog name */}
            <div className="form-row">
              <label className="form-label">Blog Name</label>
              <input
                type="text"
                name={blogname}
                value={blogname}
                onChange={(e) => setName(e.target.value)}
                className="form-input"
                required
              />
            </div>
            {/* short desc */}
            <div className="form-row">
              <label className="form-label"> Short Descriptiopn</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                value={sdesc}
                name={sdesc}
                onChange={(e) => setShortdesc(e.target.value)}
                required
              />
            </div>

            {/* longdesc */}
            <div className="form-row">
              <label className="form-label">Descriptiopn</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                value={url}
                name={url}
                onChange={(e) => setLongdesc(e.target.value)}
                required
                disabled
              />
            </div>

            {/* image */}
            <div className="form-row">
              <label className="form-label">Image</label>
              <input
                type="file"
                multiple
                filename="file"
                onChange={ImageAdd}
                className="form-input"
                required
              />
            </div>
            <button type="submit" className="btn btn-block">
              submit
            </button>
          </form>
        </Wrapper>
      </div>
    </div>
  );
};
