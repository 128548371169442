import React, { useEffect, useState } from 'react'
import "./Object.css";

export const Object = () => {
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "home";

  useEffect(() => {
    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);

  return (
    <div>
      <section className="py-1" id="features">
        <div className="container px-5 my-3">
          <div className="row gx-5">
            {/* <div className="col-lg-4 mb-5 mb-lg-0"><h2 className="fw-bolder mb-0">A better way to start building.</h2></div> */}
            <div className="">
              <div className="row gx-3 row-cols-1 row-cols-md-3">
                <div className="col mb-5 h-100 px-3">
                  <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-collection"></i>
                  </div>
                  <h2 className="h5 h-box">Vision</h2>
                  <p className="mb-0 text-justify p-box p-box-font">
                    Vision Be the catalyst in creating the green society by the
                    way of developing applications and technologies, uplifting
                    lifestyle globally.
                  </p>
                </div>
                <div className="col mb-5 h-100 px-3">
                  <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-building"></i>
                  </div>
                  <h2 className="h5 h-box">Mission</h2>
                  <p className="mb-0 text-justify p-box p-box-font">
                    Building the incubation and accelerator ecosystem for
                    research & development by harnessing the best talents
                    through innovation and rapid adoption of technology
                    advancement to make a competitive edge in the market
                  </p>
                </div>
                <div className="col mb-5 mb-md-0 h-100 px-3">
                  <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
                    <i className="bi bi-toggles2"></i>
                  </div>
                  <h2 className="h5 h-box">Objectives</h2>
                  <p className="mb-0 text-justify p-box p-box-font">
                    Enhance business value and revenue Reduce ineff ciencies and
                    leakages Enhancing trust and openness Reduce energy
                    consumption Connect digitally
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
