import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";
import Wrapper from "../../style/wrappers/RegisterPage";
import { useNavigate } from "react-router-dom";

export const Team = () => {
  const [name, setName] = useState("");
  const [no, setNo] = useState("");
  const [url, setUrl] = useState("");
  const [details, setDetails] = useState("");
  const [file, setFile] = useState([]);

  const ImageAdd = (e) => {
    setFile(e.target.files[0]);
  };

  const navi = new useNavigate();

  const Submit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("no", no);
    formData.append("url", url);
    formData.append("details", details);
    formData.append("file", file);

    setName("");
    setNo("");
    setUrl("");
    setDetails("");
    console.log(formData);

    axios
      .post("/api/team/add", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        alert("Succsessfully Added");
        navi("/Ateam");
      });
  };

  return (
    <div>
      <Navbar />
      <div>
        <Link to={"/Ateam"}>
          <div className="mt-3" style={{ textAlign: "center" }}>
            <button type="button" className="btn btn-primary mt-5">
              Our Team
            </button>
          </div>
        </Link>
      </div>
      {/* FORM */}
      <div>
        <Wrapper className="">
          <form
            className="form"
            onSubmit={Submit}
            encType="multipart/form-data"
          >
            <h3>Add New Team Member</h3>

            {/* no */}
            <div className="form-row">
              <label className="form-label">Position No</label>
              <input
                type="number"
                name={no}
                onChange={(e) => setNo(e.target.value)}
                className="form-input"
                required
              />
            </div>
            {/* name */}
            <div className="form-row">
              <label className="form-label">Name</label>
              <input
                type="text"
                name={name}
                onChange={(e) => setName(e.target.value)}
                className="form-input"
                required
              />
            </div>
            {/* Url */}
            <div className="form-row">
              <label className="form-label">LinkedIn</label>
              <input
                type="text"
                name={url}
                onChange={(e) => setUrl(e.target.value)}
                className="form-input"
                required
              />
            </div>
            <div className="form-row">
              <label className="form-label">Details</label>
              <input
                type="text"
                onChange={(e) => setDetails(e.target.value)}
                className="form-input"
                required
              />
            </div>
            {/* image */}
            <div className="form-row">
              <label className="form-label">Image</label>
              <input
                type="file"
                multiple
                filename="file"
                onChange={ImageAdd}
                className="form-input"
                required
              />
            </div>
            <button type="submit" className="btn btn-block">
              submit
            </button>
          </form>
        </Wrapper>
      </div>
    </div>
  );
};
