import React from "react";

const Scroll = (props) => {
  return (
    <div
      className="bg-transparent"
      style={{ overflowY: "scroll", height: "30vh" }}
    >
      {props.children}
    </div>
  );
};

export default Scroll;
