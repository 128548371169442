// import React, { useState } from "react";
// import axios from "axios";
// import Wrapper from "../../../style/wrappers/RegisterPage.js";
// import { Navbar } from "../../../commn/Navbar.js";
// import { Link, useNavigate } from "react-router-dom";

// export const Entrepreneurshipemail = () => {
//   const [name, setName] = useState();
//   const [email, setEmail] = useState();

//   const navi = new useNavigate();

//   const Submit = (e) => {
//     e.preventDefault();

//     const newadmin = {
//       name,
//       email,
//     };
//     axios
//       .post("/api/entrepreneurshipadmin/add", newadmin, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization:
//             "Bearer " + JSON.parse(localStorage.getItem("user")).token,
//         },
//       })
//       .then((res) => {
//         alert("Successfully Added");
//         navi("/Aentraemail");
//       });
//   };

//   return (
//     <div>
//       <Navbar />
//       <Wrapper className="mt-5">
//         <div className="text-center">
//           <Link to="/Aentraemail">
//             <button className="btn btn-primary">Entrepreneurship Emails</button>
//           </Link>
//         </div>
//         <form className="form" onSubmit={Submit} style={{ marginTop: "5%" }}>
//           <h3>Add Entrepreneurship Email</h3>
//           {/* name */}
//           <div className="form-row">
//             <label className="form-label">Name</label>
//             <input
//               type={name}
//               value={name}
//               name={name}
//               onChange={(e) => setName(e.target.value)}
//               className="form-input"
//             />
//             {/* email */}
//           </div>
//           <div className="form-row">
//             <label className="form-label">Email</label>
//             <input
//               type={email}
//               value={email}
//               name={email}
//               onChange={(e) => setEmail(e.target.value)}
//               className="form-input"
//             />
//           </div>
//           <button type="submit" className="btn btn-block">
//             submit
//           </button>
//         </form>
//       </Wrapper>
//     </div>
//   );
// };

import React, { useState } from "react";
import axios from "axios";
import Wrapper from "../../../style/wrappers/RegisterPage.js";
import { Navbar } from "../../../commn/Navbar.js";
import { Link, useNavigate } from "react-router-dom";

export const Entrepreneurshipemail = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const Submit = async (e) => {
    e.preventDefault();
    setError("");

    if (!name || !email) {
      setError("Both fields are required.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    const newadmin = { name, email };

    try {
      const res = await axios.post("/api/entrepreneurshipadmin/add", newadmin, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      });
      alert("Successfully Added");
      navigate("/Aentraemail");
    } catch (err) {
      console.error(err);
      setError("Failed to add the admin.");
    }
  };

  return (
    <div>
      <Navbar />
      <Wrapper className="mt-5">
        <div className="text-center">
          <Link to="/Aentraemail">
            <button className="btn btn-primary">Entrepreneurship Emails</button>
          </Link>
        </div>
        <form className="form" onSubmit={Submit} style={{ marginTop: "5%" }}>
          <h3>Add Entrepreneurship Email</h3>
          {error && <div className="alert alert-danger">{error}</div>}
          {/* name */}
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          {/* email */}
          <div className="form-row">
            <label className="form-label">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
            />
          </div>
          <button type="submit" className="btn btn-block">
            Submit
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
