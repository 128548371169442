import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";
import Wrapper from "../../style/wrappers/RegisterPage";
import { useNavigate } from "react-router-dom";

export const Blogspdf = () => {
  const current = new Date();
  const cdate = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const [name, setName] = useState("");
  const [uploadername, setUploadername] = useState("");
  const [date, setDate] = useState(cdate);
  const [shortdesc, setShortdesc] = useState("");
  const [add, setAdd] = useState("");
  const [file, setFile] = useState([]);
  const [items, setItems] = useState([]);
  // const [url, setUrl] = useState("");

  const ImageAdd = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("user"));
    if (items) {
      setItems(items);
      const names = items.details?.name;
      setUploadername(names);
    }
  }, []);

  const navi = new useNavigate();

  const Submit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("uploadername", uploadername);
    formData.append("date", date);
    formData.append("shortdesc", shortdesc);
    formData.append("add", "notadd");
    // formData.append("url", url);
    formData.append("file", file);

    setName("");
    setUploadername("");
    setDate("");
    setShortdesc("");
    // setUrl("");
    // setLongdesc("");

    const data = {
      name,
      uploadername,
      date,
      shortdesc,
      add: "notadd",
      // url
    };

    axios
      .post("/api/blogspdf/add", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(localStorage.get("user")).token,
        },
      })
      .then((res) => {
        alert("Succsessfully Added Blogs");
        navi("/Ablogspdfnotadd");
      });
    // console.log(data);
  };

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <Link to={"/Ablogs"}>
          <button type="button" className="btn btn-primary mt-5">
            Blogs
          </button>
        </Link>
        <Link to={"/Ablogspdfnotadd"}>
          <button type="button" className="btn btn-primary mt-5">
            Document Blogs
          </button>
        </Link>
      </div>
      {/* FORM */}
      <div>
        <Wrapper className="">
          <form
            className="form "
            onSubmit={Submit}
            encType="multipart/form-data"
          >
            <h3>Add New Blog</h3>

            {/*  */}
            <div className="form-row">
              <label className="form-label">Name</label>
              <input
                type="text"
                name={uploadername}
                value={JSON.parse(localStorage.getItem("user")).name}
                onChange={(e) => setUploadername(e.target.value)}
                className="form-input"
                disabled
              />
            </div>
            {/* date */}
            <div className="form-row">
              <label className="form-label">Date</label>
              <input
                type="text"
                value={cdate}
                name={cdate}
                onChange={(e) => setDate(e.target.value)}
                className="form-input"
                disabled
              />
            </div>
            {/*Blog name */}
            <div className="form-row">
              <label className="form-label">Blog Name</label>
              <input
                type="text"
                name={name}
                onChange={(e) => setName(e.target.value)}
                className="form-input"
                required
              />
            </div>
            {/* short desc */}
            <div className="form-row">
              <label className="form-label"> Short Descriptiopn</label>
              <textarea
                className="form-control"
                rows="4"
                value={shortdesc}
                name={shortdesc}
                onChange={(e) => setShortdesc(e.target.value)}
                required
              />
            </div>

            {/* url */}
            <div className="form-row">
              <label className="form-label">Document(PDF)</label>
              <input
                type="file"
                multiple
                filename="file"
                onChange={ImageAdd}
                className="form-input"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary container">
              submit
            </button>
          </form>
        </Wrapper>
      </div>
    </div>
  );
};
