import React, { useEffect, useState } from "react";
import axios from "axios";
import Wrapper from "../../../style/wrappers/RegisterPage.js";
import { Navbar } from "../../../commn/Navbar.js";
import { useNavigate, useParams, Link } from "react-router-dom";

export const InternshipemailUpdate = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL

  useEffect(() => {
    // Fetch the current details for the specified ID
    const fetchInternshipAdmin = async () => {
      try {
        const response = await axios.get(`/api/internshipadmin/get/${id}`);
        setName(response.data.name);
        setEmail(response.data.email);
      } catch (err) {
        console.error("Error fetching internship admin details:", err);
      }
    };

    fetchInternshipAdmin();
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      await axios.put(
        `/api/internshipadmin/update/${id}`,
        { name, email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );
      alert("Updated Internship admin email");
      navigate("/Ainternshipemail");
    } catch (error) {
      console.error("Error updating internship email:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Wrapper className="mt-5">
        <div className="text-center">
          <Link to="/Ainternshipemail">
            <button className="btn btn-primary">
              Back to Internship Emails
            </button>
          </Link>
        </div>
        <form
          className="form"
          onSubmit={handleUpdate}
          style={{ marginTop: "5%" }}
        >
          <h3>Update Internship Email</h3>
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
            />
          </div>
          <button type="submit" className="btn btn-block">
            Update
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
