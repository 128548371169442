import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext.js";
import Register from "./admin/user/Register.js";
import { Login } from "./admin/user/Login.js";
import { User } from "./admin/user/User.js";
import { Home } from "./client/Home.js";
import { InnovationAdd } from "./admin/innovation/InnovationAdd.js";
import { InnovationDisplay } from "./admin/innovation/InnovationDisplay.js";
import { UserUpdate } from "./admin/user/UserUpdate.js";
import { InnovationUpdate } from "./admin/innovation/InnovationUpdate.js";
import { Innovation } from "./client/product and solution/Innovation.js";
import { InnovationDetails } from "./client/product and solution/InnovationDetails.js";
import { AddUniversity } from "./admin/university/AddUniversity.js";
import { DisplayUniversity } from "./admin/university/DisplayUniversity.js";
import { UpdateUniversity } from "./admin/university/UpdateUniversity.js";
import { University } from "./client/colabarations/University.js";
import { IndustryDisplay } from "./admin/industry/IndustryDisplay.js";
import { IndustryAdd } from "./admin/industry/IndustryAdd.js";
import { IndustryUpdate } from "./admin/industry/IndustryUpdate.js";
import { OngoingAdd } from "./admin/ongoingprograms/OngingAdd.js";
import { OngoingDisplay } from "./admin/ongoingprograms/OngoingDisplay.js";
import { Industry } from "./client/colabarations/Industry.js";
import { Ongoing } from "./client/colabarations/Ongoing.js";
import { ContactUS } from "./client/contact/contacus/Contact.js";
import ContactusEmailAdmin from "./admin/contactus/ContactusEmailAdmin.js";
import { ContactusEmailAdminUpdate } from "./admin/contactus/ContactusEmailAdminUpdate.js";
import { ContactusEmailAdminDisplay } from "./admin/contactus/ContactusEmailAdminDisplay.js";
import { Team } from "./admin/team/Team.js";
import { TeamDisplay } from "./admin/team/TeamDisplay.js";
import { OurTeam } from "./client/contact/ourteam/OurTeam.js";
import { TeamUpdate } from "./admin/team/TeamUpdate.js";
import { Intrapreneurship } from "./client/studio/intrapreneurship/Intrapreneurship.js";
import { Internship } from "./client/studio/internship/Internship.js";
import { Entrepeneurship } from "./client/studio/entrepreneurship/Entrepeneurship.js";
import { Intrapreneurshipemail } from "./admin/studios/Intrapreneurship/Intrapreneurshipemail.js";
import { IntrapreneurshipemailDisplay } from "./admin/studios/Intrapreneurship/IntrapreneurshipemailDisplay.js";
import { IntrapreneurshipemailUpdate } from "./admin/studios/Intrapreneurship/IntrapreneurshipemailUpdate.js";
import { IntrapreneurshipA } from "./admin/studios/Intrapreneurship/IntrapreneurshipA.js";
import { Internshipemail } from "./admin/studios/internship/internshipemail.js";
import { InternshipemailUpdate } from "./admin/studios/internship/InternshipemailUpdate.js";
import { InternshipA } from "./admin/studios/internship/InternshipA.js";
import { InternshipemailDisplay } from "./admin/studios/internship/InternshipemailDisplay.js";
import { InternshipVideoUpdate } from "./admin/studios/internship/InternshipVideoUpdate.js";
import { InternshipVideoDisplay } from "./admin/studios/internship/InternshipVideoDisplay.js";
import { InternshipVideoAdd } from "./admin/studios/internship/InternshipVideoAdd.js";
import { Entrepreneurshipemail } from "./admin/studios/entrepreneurship/Entrepreneurshipemail.js";
import { EntrepreneurshipemailDisplay } from "./admin/studios/entrepreneurship/EntrepreneurshipemailDisplay.js";
import { EntrepreneurshipemailUpdate } from "./admin/studios/entrepreneurship/EntrepreneurshipemailUpdate.js";
import { EntraForm } from "./client/studio/entrepreneurship/EntraForm.js";
import { EntrepreneurshipA } from "./admin/studios/entrepreneurship/EntrepreneurshipA.js";
import { EntrepreneurshipDetails } from "./admin/studios/entrepreneurship/EntrepreneurshipDetails.js";
import { Blogs } from "./admin/blogs/Blogs.js";
import { DisplayBlogs } from "./admin/blogs/DisplayBlogs.js";
import { CBlogs } from "./client/news and blogs/blogs/CBlogs.js";
import { CBlogsMore } from "./client/news and blogs/blogs/CBlogsMore.js";
import { News } from "./admin/news/News.js";
import { NewsAdd } from "./admin/news/NewsAdd.js";
import { NewsMore } from "./client/news and blogs/news/NewsMore.js";
import { NewsUpdate } from "./admin/news/NewsUpdate.js";
import { UpadateBlogs } from "./admin/blogs/UpadateBlogs.js";
import { CBlogsMessage } from "./client/news and blogs/blogs/CBlogsMessage.js";
import { BlogsMessageAllAdmin } from "./admin/blogs/BlogsMessageAllAdmin.js";
import { Search } from "./search/Search.js";
import { Blogspdf } from "./admin/blogs/Blogspdf.js";
import { BlogsnotaddView } from "./admin/blogs/BlogsnotaddView.js";
import { BlogsPdfUpdate } from "./admin/blogs/blogspdf/BlogsPdfUpdate.js";
import { BlogsPdfAddToBlogs } from "./admin/blogs/blogspdf/BlogsPdfAddToBlogs.js";
import { OngoingUpdate } from "./admin/ongoingprograms/OngoingUpdate.js";
import { InternshipForm } from "./client/studio/internship/InternshipForm.js";
import { BlogsPDFView } from "./client/news and blogs/blogs/BlogsPDFView.js";
import { BlogsPDF } from "./client/news and blogs/blogs/BlogsPDF.js";
import { NewsC } from "./client/news and blogs/news/News.js";
import { IntraProgram } from "./client/studio/intrapreneurship/IntraProgram.js";
import { EntreProgram } from "./client/studio/entrepreneurship/EntreProgram.js";

function App() {
  //protect Routes for admin side
  const ProtectedRoute = ({ children }) => {
    const { user } = useContext(AuthContext);
    const last_login = JSON.parse(localStorage.getItem("last_login"));
    if (user && last_login < Date.now() - 1000 * 60 * 60 * 3) {
      localStorage.removeItem("user");
      alert("Your session has expired. Please login again");
      return <Navigate to="/admin" />;
    }

    if (!user) {
      return <Navigate to="/admin" />;
    }

    return children;
  };

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* Admin Routes */}
          <Route exact path={"/admin"} element={<Login />} />
          <Route
            exact
            path={"/reg"}
            element={
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/user"}
            element={
              <ProtectedRoute>
                <User />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/updateuser/:id"}
            element={
              <ProtectedRoute>
                <UserUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Ainnovation"}
            element={
              <ProtectedRoute>
                <InnovationDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/innovationadd"}
            element={
              <ProtectedRoute>
                <InnovationAdd />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/updateinnovation/:id"}
            element={
              <ProtectedRoute>
                <InnovationUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Auniversity"}
            element={
              <ProtectedRoute>
                <DisplayUniversity />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/universityadd"}
            element={
              <ProtectedRoute>
                <AddUniversity />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/updateuniversity/:id"}
            element={
              <ProtectedRoute>
                <UpdateUniversity />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aindustry"}
            element={
              <ProtectedRoute>
                <IndustryDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/industryadd"}
            element={
              <ProtectedRoute>
                <IndustryAdd />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/updateindustry/:id"}
            element={
              <ProtectedRoute>
                <IndustryUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aongoing"}
            element={
              <ProtectedRoute>
                <OngoingDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/ongoingupdate/:id"}
            element={
              <ProtectedRoute>
                <OngoingUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/ongoingadd"}
            element={
              <ProtectedRoute>
                <OngoingAdd />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Acontactadminadd"}
            element={
              <ProtectedRoute>
                <ContactusEmailAdmin />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Acontactadmin"}
            element={
              <ProtectedRoute>
                <ContactusEmailAdminDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Acontactadminupdate/:id"}
            element={
              <ProtectedRoute>
                <ContactusEmailAdminUpdate />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path={"/teamadd"}
            element={
              <ProtectedRoute>
                <Team />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Ateam"}
            element={
              <ProtectedRoute>
                <TeamDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/AteamUpdate/:id"}
            element={
              <ProtectedRoute>
                <TeamUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aintraemailadd"}
            element={
              <ProtectedRoute>
                <Intrapreneurshipemail />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aintraemail"}
            element={
              <ProtectedRoute>
                <IntrapreneurshipemailDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aintraemailupdate/:id"}
            element={
              <ProtectedRoute>
                <IntrapreneurshipemailUpdate />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path={"/Aintra"}
            element={
              <ProtectedRoute>
                <IntrapreneurshipA />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Ainternshipadd"}
            element={
              <ProtectedRoute>
                <Internshipemail />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/InternshipVideoUpdate/:id"}
            element={
              <ProtectedRoute>
                <InternshipVideoUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Ainternshipemail"}
            element={
              <ProtectedRoute>
                <InternshipemailDisplay />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path={"/AinternshipemailUpdate/:id"}
            element={
              <ProtectedRoute>
                <InternshipemailUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Ainternship"}
            element={
              <ProtectedRoute>
                <InternshipA />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aentraadd"}
            element={
              <ProtectedRoute>
                <Entrepreneurshipemail />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aentraemail"}
            element={
              <ProtectedRoute>
                <EntrepreneurshipemailDisplay />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aentraemailupdate/:id"}
            element={
              <ProtectedRoute>
                <EntrepreneurshipemailUpdate />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path={"/Aentra"}
            element={
              <ProtectedRoute>
                <EntrepreneurshipA />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Aentradetails/:id"}
            element={
              <ProtectedRoute>
                <EntrepreneurshipDetails />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Ablogs"}
            element={
              <ProtectedRoute>
                <Blogs />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/Ablogsdisplay"}
            element={
              <ProtectedRoute>
                <DisplayBlogs />
              </ProtectedRoute>
            }
          />
          <Route exact path={"/Anews"} element={<News />} />
          <Route exact path={"/Anewsadd"} element={<NewsAdd />} />
          <Route
            exact
            path={"/updatenews/:id"}
            element={
              <ProtectedRoute>
                <NewsUpdate />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={"/updateblog/:id"}
            element={
              <ProtectedRoute>
                <UpadateBlogs />
              </ProtectedRoute>
            }
          />
          <Route exact path={"/Ablogsmsg"} element={<BlogsMessageAllAdmin />} />
          <Route exact path={"/ABlogpdf"} element={<Blogspdf />} />
          <Route
            exact
            path={"/Ablogspdfnotadd"}
            element={<BlogsnotaddView />}
          />
          <Route exact path={"/Abpu/:id"} element={<BlogsPdfUpdate />} />
          <Route exact path={"/Abpatb/:id"} element={<BlogsPdfAddToBlogs />} />
          <Route
            exact
            path={"/Ainternshipvideoadd"}
            element={<InternshipVideoAdd />}
          />
          <Route
            exact
            path={"/Ainternshipvideodisplay"}
            element={<InternshipVideoDisplay />}
          />

          {/* Client Routes */}
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/innovation"} element={<Innovation />} />
          <Route
            exact
            path={"/innovationdetails/:id"}
            element={<InnovationDetails />}
          />
          <Route exact path={"/university"} element={<University />} />
          <Route exact path={"/industry"} element={<Industry />} />
          <Route exact path={"/ongoing"} element={<Ongoing />} />
          <Route exact path={"/contactus"} element={<ContactUS />} />
          <Route exact path={"/team"} element={<OurTeam />} />
          <Route
            exact
            path={"/intrapeneurship"}
            element={<Intrapreneurship />}
          />
          <Route exact path={"/internship"} element={<Internship />} />
          <Route exact path={"/internshipform"} element={<InternshipForm />} />
          <Route exact path={"/entrepreurship"} element={<Entrepeneurship />} />
          <Route exact path={"/entraform"} element={<EntraForm />} />
          <Route exact path={"/blogs"} element={<CBlogs />} />
          <Route exact path={"/blogs/:id"} element={<CBlogsMore />} />
          <Route exact path={"/news/:id"} element={<NewsMore />} />
          <Route exact path={"/news"} element={<NewsC />} />
          <Route exact path={"/cm"} element={<CBlogsMessage />} />
          <Route exact path={"/tt"} element={<BlogsPDFView />} />
          <Route exact path={"/bp"} element={<BlogsPDF />} />
          <Route exact path={"/intraprogram"} element={<IntraProgram />} />
          <Route exact path={"/entreprogram"} element={<EntreProgram/>}/>
          {/* <Route exact path={"/sr"} element={<Search/>} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
