import React, { useEffect, useRef, useState } from "react";
import PDF from "./AS.pdf";
// import * as PDFJS from 'pdfjs-dist';
import * as pdfjs from "pdfjs-dist";

export const BlogsPDFView = () => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const [Cp, setCp] = useState("");
  const [CA, setCA] = useState("");

  // var dice = Math.floor(Math.random()* 6) +1;
  // document.querySelector("#current-0").textContent = dice;
  // const canvasRef = useRef(null);

  // useEffect(() => {
  // 	load();
  // }, []);

  // const load = async () => {
  // 	// We import this here so that it's only loaded during client-side rendering.
  // 	// const pdfJS = await import("pdfjs-dist/build/pdf");
  // 	// pdfjs.GlobalWorkerOptions.workerSrc =
  // 	// 	window.location.origin + "/pdf.worker.min.js";
  // 	const pdf = await pdfjs.getDocument(PDF).promise;

  // 	const page = await pdf.getPage(1);
  // 	const viewport = page.getViewport({ scale: 1.5 });

  // 	// Prepare canvas using PDF page dimensions.
  // 	const canvas = canvasRef.current;
  // 	const canvasContext = canvas.getContext("2d");
  // 	canvas.height = viewport.height;
  // 	canvas.width = viewport.width;

  // 	// Render PDF page into canvas context.
  // 	const renderContext = { canvasContext, viewport };
  // 	page.render(renderContext);
  // };

  // useEffect(() => {
  // 	Load();
  // }, []);

  const pageNum = document.querySelector("page_num");
  const pageCount = document.querySelector("page_count");
  const currentPage = document.querySelector("current_page");
  const previousPage = document.querySelector("prev_page");
  const nextPage = document.querySelector("next_page");
  const zoomIn = document.querySelector("zoom_in");
  const zoomOut = document.querySelector("zoom_out");

  const initialState = {
    pdfDoc: "",
    currentPage: 1,
    pageCount: 0,
    zoom: 1,
  };

  const renderPage = () => {
    // Load the first page.
    // console.log(initialState.pdfDoc, "pdfDoc");
    initialState.pdfDoc.getPage(initialState.currentPage).then((page) => {
      console.log("page", page);

      const canvas = document.querySelector("canvas");
      const ctx = canvas.getContext("2d");
      const viewport = page.getViewport({
        scale: initialState.zoom,
      });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      // Render the PDF page into the canvas context.
      const renderCtx = {
        canvasContext: ctx,
        viewport: viewport,
      };

      page.render(renderCtx);

      setCp(initialState.currentPage);
      setCA(initialState.pdfDoc._pdfInfo.numPages);

      // pageCount.textContent = initialState.pdfDoc.numPages;
      // pageNum.textContent = initialState.currentPage;
    });
  };

  pdfjs
    .getDocument(PDF)
    .promise.then((res) => {
      initialState.pdfDoc = res;
      // console.log("pdfDocument", initialState.pdfDoc);
      renderPage();
    })
    .catch((err) => {
      alert(err.message);
    });

  const Pagenext = () => {
    if (
      initialState.pdfDoc === null ||
      Cp >= initialState.pdfDoc._pdfInfo.numPages
    ) {
      renderPage();
    } else {
      // Cp++;
      // currentPage.value = initialState.currentPage;
      initialState.currentPage++;
      currentPage.value = initialState.currentPage;
      renderPage();
    }
  };

  return (
    <div className="container">
      <li className="navigation__item">
        {/* <!-- Navigate to the Previous and Next pages --> */}
        <a href="#" className="previous round" id="prev_page">
          <i className="fas fa-arrow-left"></i>
        </a>
        {/* <!-- Navigate to a specific page --> */}
        {/* <input type="number" value="1" id="current_page" /> */}
        <a href="#" className="next round" id="next_page">
          <i className="fas fa-arrow-right"></i>
        </a>
        {/* <!-- Page Info --> */}
        Page
        <span>{Cp}</span>
        {/* <span id="page_num">{Cp}</span> */}
        of
        {/* <span id="page_count"></span> */}
        <span>{CA}</span>
        <button type="button" onClick={Pagenext}>
          Next
        </button>
      </li>

      {/* <!-- Canvas to place the PDF --> */}
      <canvas id="canvas" style={{ height: "100vh" }} />
    </div>
  );
};
