import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";

export const InnovationDisplay = () => {
  const [items, setItem] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = new useNavigate();

  useEffect(() => {
    axios
      .get("/api/innovation/get")
      .then((res) => {
        setItem(res.data);
        console.log("Data fetched successfully:", res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //delete user
  const Deleteinnovation = (id) => {
    axios.delete(`/api/innovation/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    alert("Deleted Innovation");
    navigate("/Ainnovation");
  };

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="mt-5" style={{ textAlign: "center" }}>
          <Link to={"/innovationadd"}>
            <button type="button" className="btn btn-primary mt-3">
              ADD Innovations
            </button>
          </Link>
        </div>
        <div className="mt-5">
          <div>
            <h2>Innovations</h2>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <table
              className="table shadow"
              style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
            >
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Item Name</th>
                  <th scope="col" style={{ width: "25%" }}>
                    Short Description
                  </th>
                  <th scope="col" style={{ width: "25%" }}>
                    Description
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {items.map((innovation) => {
                  return (
                    <tr key={innovation._id}>
                      <td>
                        <img
                          className="rounded"
                          style={{ width: "50%", height: "50%" }}
                          src={"/upload/" + innovation.image}
                          alt={innovation.name}
                        />
                      </td>
                      <td>{innovation.name}</td>
                      <td>
                        <textarea className="form-control" rows={8} disabled>
                          {innovation.desc}
                        </textarea>
                      </td>
                      <td>
                        <textarea className="form-control" rows={8} disabled>
                          {innovation.longdesc}
                        </textarea>
                      </td>
                      <td>
                        <Link to={`/updateinnovation/${innovation._id}`}>
                          <button className="btn">Update</button>
                        </Link>
                        <br />
                        <button
                          className="btn"
                          onClick={() => {
                            Deleteinnovation(innovation._id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
