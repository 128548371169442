import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../../style/wrappers/RegisterPage";

export const ContactusEmailAdminUpdate = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const { id } = useParams(); // Destructure id from useParams
  const navigate = useNavigate(); // Correct usage of useNavigate

  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      await axios.put(
        `/api/contactadmin/update/${id}`,
        { name, email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );
      alert("Updated email");
      navigate("/Acontactadmin");
    } catch (error) {
      console.error("Error updating email:", error);
    }
  };

  return (
    <div>
      <Wrapper className="full-page">
        <form className="form" onSubmit={handleUpdate}>
          <h3>Update Internship Video</h3>
          {/* Name */}
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
            />
          </div>
          <button type="submit" className="btn btn-block">
            Update
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
