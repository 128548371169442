// import axios from "axios";
// import mongoose from "mongoose";
// import React, { useEffect, useState } from "react";
// import "../../style/css/admin/User.css";
// import { Navbar } from "../../commn/Navbar.js";
// import NavLink, { Link, useNavigate, useParams } from "react-router-dom";
// // const url = "http://localhost:3000/";
// import countapi from "countapi-js";

// export const User = () => {
//   const [data, setData] = useState([]);
//   const [item, setItem] = useState([]);
//   const [itemTeam, setItemTeam] = useState([]);
//   const [itemsContact, setItemContact] = useState([]);
//   const [itemsBolgs, setItemBolgs] = useState([]);
//   const [itemsUniversity, setItemUniversity] = useState([]);
//   const [itemsNews, setItemNews] = useState([]);
//   const [itemsIndustry, setItemIndustry] = useState([]);
//   const [itemsOngoing, setItemOngoing] = useState([]);
//   const [itemsInnovation, setItemInnovation] = useState([]);
//   const [itemsIntrapeneurship, setItemIntrapeneurship] = useState([]);
//   const [itemsEntrepreurship, setItemEntrepreurship] = useState([]);
//   const [itemsInternship, setItemInternship] = useState([]);
//   const [totalSum, setTotalSum] = useState(0);

//   const navigate = useNavigate();
//   const params = useParams();

//   countapi
//     .get("159.223.218.132", "4d870ea4-fcc3-4472-a604-7c5b67e8db2f")
//     .then((result) => {
//       // console.log(result.value);
//       setItem(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "3774bf2b-6b89-49ea-8bc9-c2bb0ef892e0")
//     .then((result) => {
//       // console.log(result.value);
//       setItemTeam(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "b31dc37e-04db-4768-859a-1452b19fde1e")
//     .then((result) => {
//       // console.log(result.value);
//       setItemContact(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "272af60f-76ca-40f8-af83-7a26e8c1cdbe")
//     .then((result) => {
//       // console.log(result.value);
//       setItemBolgs(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "b10a2d15-d235-48b1-87a2-d354110c40ef")
//     .then((result) => {
//       // console.log(result.value);
//       setItemNews(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "57626701-9204-442d-98c8-818cb8b3e321")
//     .then((result) => {
//       // console.log(result.value);
//       setItemUniversity(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "5b6042cd-beb6-4b52-a136-cfd1bd0d30b9")
//     .then((result) => {
//       // console.log(result.value);
//       setItemIndustry(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "a1391ce5-d8c6-42aa-b7a6-76e7dfb8fc5c")
//     .then((result) => {
//       // console.log(result.value);
//       setItemOngoing(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "b513d986-343b-4cd1-8c44-3368b16e7be0")
//     .then((result) => {
//       // console.log(result.value);
//       setItemInnovation(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "9cf31bcf-21e5-4411-8189-a66d265434b6")
//     .then((result) => {
//       // console.log(result.value);
//       setItemIntrapeneurship(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "3928a4af-bea9-4ecf-8e8c-87b8c2b288c2")
//     .then((result) => {
//       // console.log(result.value);
//       setItemEntrepreurship(result.value);
//     });
//   countapi
//     .get("159.223.218.132", "7f5c917a-0783-49dc-b1fb-f07d2d4a00c4")
//     .then((result) => {
//       // console.log(result.value);
//       setItemInternship(result.value);
//     });

//   //  const countEl = document.getElementById('count');

//   //  updateVisitCount();

//   //  function updateVisitCount() {
//   //   fetch('https://api.countapi.xyz/get/159.223.218.132/contactus').then(res => res.json()).then(res => {
//   //     countEl.innerHTML = res.value;
//   //   })
//   //  }

//   //  function cb(response){
//   //   document.querySelector("#visits").textContent = response.value;
//   //  }
//   //  <script async src="https://api.countapi.xyz/hit/159.223.218.132/3774bf2b-6b89-49ea-8bc9-c2bb0ef892e0?callback=cb"></script>

//   useEffect(() => {
//     try {
//       const user = JSON.parse(localStorage.getItem("user"));
//       console.log(user);
//       axios
//         .get("/api/user/get", {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: "Bearer " + user?.token,
//           },
//         })
//         .then((res) => {
//           setData(res.data);
//         });
//     } catch (err) {
//       console.log(err);
//     }
//   }, []);

//   const deleteUser = (id) => {
//     if (window.confirm("Are you sure you want to delete this user?")) {
//       axios
//         .delete(`/api/user/delete/${id}`, {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${
//               JSON.parse(localStorage.getItem("user")).token
//             }`,
//           },
//         })
//         .then((response) => {
//           console.log("Delete response:", response);
//           alert("User Deleted!");
//           navigate("/user");
//         })
//         .catch((error) => {
//           console.error("Error deleting user:", error);
//           alert("Failed to delete user");
//         });
//     }
//   };

//   return (
//     <div>
//       <div>
//         <Navbar />
//       </div>

//       {/* <div className="border-3 rounded-3 shadow" style={{ width: "45%", marginLeft: "30%", marginTop: "4%" }}>
//         <div className="text-center">
//           <h4>Contact Us page has</h4>
//           <h1 id="count">0</h1>
//           <h4>views</h4>
//           <h1>This site has been visited <span id="visits"></span> times. </h1>
//         </div>
//       </div> */}

//       <div className="row " style={{ textAlign: "center", marginTop: "7%" }}>
//         <div className="dbtn col-sm-1 mt-5 mb-3">
//           <Link to="/reg">
//             <button className="btn btn-primary mt-5">Add User</button>
//           </Link>
//         </div>
//         <div className="td col container">
//           <h1 className="mb-3">User Details</h1>
//           <table className="table shadow container" style={{ width: "70%" }}>
//             <thead>
//               <tr>
//                 <th scope="col">User Name</th>
//                 <th scope="col">Email</th>
//                 <th scope="col">Status</th>
//                 <th scope="col"></th>
//               </tr>
//             </thead>
//             <tbody>
//               {data.map((user) => {
//                 return (
//                   <tr>
//                     <td>{user.name}</td>
//                     <td>{user.email}</td>
//                     <td>{user.cadmin}</td>
//                     <td>
//                       <Link to={`/updateuser/${user._id}`}>
//                         <button className="btn">Update</button>
//                       </Link>
//                       <button
//                         className="btn"
//                         onClick={() => {
//                           deleteUser(user._id);
//                         }}
//                       >
//                         Delete
//                       </button>
//                     </td>
//                   </tr>
//                 );
//               })}
//             </tbody>
//           </table>
//         </div>
//       </div>
//       <div className="td col container text-center">
//         <h1 className="mb-3">User viewed count</h1>
//         <table className="table shadow container" style={{ width: "70%" }}>
//           <thead>
//             <tr>
//               <th scope="col">Page</th>
//               <th scope="col">Count</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>This Website was viewed</td>
//               <td>{item}</td>
//             </tr>
//             <tr>
//               <td>Our team Page was viewed</td>
//               <td>{itemTeam}</td>
//             </tr>
//             <tr>
//               <td>Contact us Page was viewed</td>
//               <td>{itemsContact}</td>
//             </tr>
//             <tr>
//               <td>Bolgs Page was viewed</td>
//               <td>{itemsBolgs}</td>
//             </tr>
//             <tr>
//               <td>News Page was viewed </td>
//               <td>{itemsNews}</td>
//             </tr>
//             <tr>
//               <td>University Partnership Page was viewed</td>
//               <td>{itemsUniversity}</td>
//             </tr>
//             <tr>
//               <td>Industry Partnership Page</td>
//               <td>{itemsIndustry}</td>
//             </tr>
//             <tr>
//               <td>Ongoing Partnership Page</td>
//               <td>{itemsOngoing}</td>
//             </tr>
//             <tr>
//               <td>Innovation Page</td>
//               <td>{itemsInnovation}</td>
//             </tr>
//             <tr>
//               <td>Intrapreneurship Page</td>
//               <td>{itemsIntrapeneurship}</td>
//             </tr>
//             <tr>
//               <td>Entrepreneurship Page</td>
//               <td>{itemsEntrepreurship}</td>
//             </tr>
//             <tr>
//               <td>Internship Page</td>
//               <td>{itemsInternship}</td>
//             </tr>
//             <tr>
//               <td></td>
//               <td></td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../style/css/admin/User.css";
import { Navbar } from "../../commn/Navbar.js";
import { Link, useNavigate } from "react-router-dom";

export const User = () => {
  const [data, setData] = useState([]);
  const [viewCounts, setViewCounts] = useState({
    home: 0,
    team: 0,
    contact: 0,
    blogs: 0,
    news: 0,
    university: 0,
    industry: 0,
    ongoing: 0,
    innovation: 0,
    intrapreneurship: 0,
    entrepreneurship: 0,
    internship: 0,
  });

  // Fetch view count from backend
  const fetchViewCount = async (page) => {
    try {
      const response = await axios.get(
        `https://embryo.slt.lk/api/pageView/${page}`
      );
      return response.data.count;
    } catch (error) {
      console.error(`Error fetching view count for ${page}:`, error);
      return 0;
    }
  };

  const navigate = useNavigate();

  // Fetch and update view counts
  useEffect(() => {
    const fetchAndUpdateViews = async () => {
      const pages = [
        "home",
        "team",
        "contact",
        "blogs",
        "news",
        "university",
        "industry",
        "ongoing",
        "innovation",
        "intrapreneurship",
        "entrepreneurship",
        "internship",
      ];

      // Fetch view counts
      const viewCounts = {};
      for (const page of pages) {
        viewCounts[page] = await fetchViewCount(page);
      }
      setViewCounts(viewCounts);
    };

    fetchAndUpdateViews();
  }, []);

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      axios
        .get("/api/user/get", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user?.token,
          },
        })
        .then((res) => {
          setData(res.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const deleteUser = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      axios
        .delete(`/api/user/delete/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        })
        .then((response) => {
          alert("User Deleted!");
          navigate("/user");
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
          alert("Failed to delete user");
        });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="row" style={{ textAlign: "center", marginTop: "7%" }}>
        <div className="dbtn col-sm-1 mt-5 mb-3">
          <Link to="/reg">
            <button className="btn btn-primary mt-5">Add User</button>
          </Link>
        </div>
        <div className="td col container">
          <h1 className="mb-3">User Details</h1>
          <table className="table shadow container" style={{ width: "70%" }}>
            <thead>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.cadmin}</td>
                  <td>
                    <Link to={`/updateuser/${user._id}`}>
                      <button className="btn">Update</button>
                    </Link>
                    <button
                      className="btn"
                      onClick={() => deleteUser(user._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="td col container text-center">
        <h1 className="mb-3">Page View Counts</h1>
        <table className="table shadow container" style={{ width: "70%" }}>
          <thead>
            <tr>
              <th scope="col">Page</th>
              <th scope="col">Count</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(viewCounts).map((page) => (
              <tr key={page}>
                <td>
                  {page.charAt(0).toUpperCase() + page.slice(1)} Page was viewed
                </td>
                <td>{viewCounts[page]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
