import React, { useState, useEffect } from "react";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";

export const IntraProgram = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Auto-scroll feature
  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedImage((prevSelectedImage) =>
        prevSelectedImage < images.length - 1 ? prevSelectedImage + 1 : 0
      );
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const styles = {
    container: {
      backgroundColor: "#e0e0e0",
      paddingBottom: "2rem",
    },
    content: {
      margin: "0 3%",
    },
    header: {
      textAlign: "center",
      paddingTop: "3rem",
      marginTop: "3rem",
    },
    section: {
      backgroundColor: "#fefefe",
      borderRadius: "0.5rem",
      border: "2px solid #0056b3",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
      paddingTop: "2rem",
      paddingBottom: "1.5rem",
      marginTop: "3rem",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "flex-start",
    },
    description: {
      flex: 1,
      fontSize: "1 rem",
      color: "#222222",
      lineHeight: "1.6",
      padding: isMobile ? "0 1rem" : "0 1.5rem",
    },
    mainImageContainer: {
      flex: 1,
      position: "relative",
      padding: isMobile ? "10px" : "20px",
      display: "flex",
      justifyContent: "center",
      marginTop: isMobile ? "1rem" : "0",
    },
    mainImage: {
      width: isMobile ? "90%" : "80%",
      height: "auto",
      borderRadius: "0.5rem",
      objectFit: "cover",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    gallery: {
      display: "flex",
      overflowX: "auto",
      gap: "1rem",
      marginTop: "1.5rem",
      justifyContent: "center",
    },
    galleryItem: {
      minWidth: isMobile ? "120px" : "180px",
      cursor: "pointer",
      position: "relative",
      borderRadius: "0.5rem",
    },
    galleryImg: {
      width: "100%",
      height: "auto",
      borderRadius: "0.5rem",
      objectFit: "cover",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
    },
    prevNextButtons: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "2rem",
      color: "#0056b3",
      cursor: "pointer",
      zIndex: 10,
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      padding: "0.5rem",
      borderRadius: "50%",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    prevButton: {
      left: "10px",
    },
    nextButton: {
      right: "10px",
    },
    sectionTitle: {
      fontSize: "1.75rem",
      color: "#0056b3",
      marginBottom: "1.5rem",
      textAlign: "center",
    },
    paragraph: {
      marginBottom: "1.5rem",
    },
  };

  const images = Array.from({ length: 8 }, (_, index) =>
    require(`../../../image/intraProgram${index + 1}.jpg`)
  );

  const handlePrev = () => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage > 0 ? prevSelectedImage - 1 : images.length - 1
    );
  };

  const handleNext = () => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage < images.length - 1 ? prevSelectedImage + 1 : 0
    );
  };

  return (
    <div style={styles.container}>
      <Navbar />

      <div className="mt-5 my-5" style={styles.content}>
        <div style={styles.header}>
          <h1 className="pt-5 mt-5">Intrapreneurship Studio Program 2023</h1>
        </div>

        <div
          className="bgwhite rounded-5 border-2 border-primary border-bottom border-top shadow pt-5 mx-3 pb-4 mt-5"
          style={styles.section}
        >
          <div style={styles.description}>
            <div style={styles.sectionTitle}>
              Experience Innovation: SLT-MOBITEL Intrapreneurship Studio Demo
              Day
            </div>
            <p style={styles.paragraph}>
              <strong>
                November 28th, 2023
              </strong>
            </p>
            <p style={styles.paragraph}>
            <strong>A Milestone of Creativity and Ingenuity</strong><br />
              SLT-MOBITEL proudly hosted the “Demo Day” of its Intrapreneurship
              Studio, a celebration of extraordinary talent and groundbreaking
              innovation.
            </p>
            <p style={styles.paragraph}>
              <strong>A Platform for Visionary Innovators</strong>
              <br />
              The event served as a platform where visionary innovators
              showcased their remarkable ideas, pushing the boundaries of
              creativity and ingenuity.
            </p>
            <p style={styles.paragraph}>
              <strong>A Significant Step Forward</strong>
              <br />
              This milestone event marked a significant step in SLT-MOBITEL’s
              journey towards becoming a more innovative and future-focused
              organization.
            </p>
            <p style={styles.paragraph}>
              Explore the highlights and witness the brilliance that emerged
              from this dynamic day.
            </p>
          </div>

          <div style={styles.mainImageContainer}>
            <img
              src={images[selectedImage]}
              alt={`Program highlight ${selectedImage + 1}`}
              style={styles.mainImage}
            />
            <div
              style={{ ...styles.prevNextButtons, ...styles.prevButton }}
              onClick={handlePrev}
            >
              &lt;
            </div>
            <div
              style={{ ...styles.prevNextButtons, ...styles.nextButton }}
              onClick={handleNext}
            >
              &gt;
            </div>
          </div>
        </div>

        <div className="gallery" style={styles.gallery}>
          {images.map((image, index) => (
            <div
              className="gallery-item"
              key={index}
              style={styles.galleryItem}
              onClick={() => setSelectedImage(index)}
            >
              <img
                src={image}
                alt={`Program highlight ${index + 1}`}
                className="gallery-img"
                style={styles.galleryImg}
              />
            </div>
          ))}
        </div>
      </div>

      <Contact />
    </div>
  );
};
