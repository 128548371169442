import React, { useState, useEffect } from "react";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";

export const EntreProgram = () => {
  const [selectedImage, setSelectedImage] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const AUTO_CHANGE_INTERVAL = 4000;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = {
    container: {
      backgroundColor: "#e0e0e0",
      paddingBottom: "2rem",
    },
    content: {
      margin: "0 3%",
    },
    header: {
      textAlign: "center",
      paddingTop: "3rem",
      marginTop: "3rem",
      fontFamily: "'Roboto', sans-serif",
      color: "#343a40",
    },
    workshopsContainer: {
      backgroundColor: "#fefefe",
      borderRadius: "0.5rem",
      border: "2px solid #007bff",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
      padding: "2rem",
      marginTop: "3rem",
    },
    section: {
      marginTop: "3rem",
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      fontFamily: "'Roboto', sans-serif",
      color: "#495057",
    },
    description: {
      fontSize: "1rem",
      color: "#222222",
      lineHeight: "1.7",
      marginBottom: "1.5rem",
      flex: 1,
    },
    mainImageContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      marginBottom: isMobile ? "1rem" : "1.5rem",
      flex: 1,
      width: isMobile ? "100%" : "auto",
    },
    mainImage: {
      width: isMobile ? "90%" : "80%",
      height: "auto",
      borderRadius: "0.5rem",
      objectFit: "cover",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    prevNextButtons: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      fontSize: "2rem",
      color: "#0056b3",
      cursor: "pointer",
      zIndex: 10,
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      padding: "0.5rem",
      borderRadius: "50%",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    prevButton: {
      left: "10px",
    },
    nextButton: {
      right: "10px",
    },
    sectionTitle: {
      fontSize: "1.75rem",
      color: "#0056b3",
      marginBottom: "1rem",
      textAlign: "center",
      fontWeight: "bold",
    },
    paragraph: {
      marginBottom: "1.5 rem",
    },
  };

  const workshops = [
    {
      title: "1st Workshop: Design Thinking",
      date: "31st October 2023",
      description: `
        The First workshop of the “Entrepreneurship Studio Cohort 2023/24” on “Design Thinking” was successfully conducted on 31st October 2023 at SLTMobitel The Embryo Innovation Center, Trace City.
        The workshop was conducted by Mr. Mohammed Fawaz, the founder of CurveUp. We want extend our heartfelt gratitude to Mr. Fawaz for generously sharing his expertise and providing invaluable guidance.
        As The Embryo Innovation Centre, we always want to improve creativity and keep empowering our finalists through our programme. Stay tuned with us for exciting updates on our future workshops and milestones of Entrepreneurship Studio Programme Cohort 2023/24.
      `,
      images: [
        require("../../../image/entraWorkshop1_1.jpg"),
        require("../../../image/entraWorkshop1_2.jpg"),
        require("../../../image/entraWorkshop1_3.jpg"),
        require("../../../image/entraWorkshop1_4.jpg"),
      ],
    },
    {
      title: "2nd Workshop: Intellectual Property",
      date: "2nd November 2023",
      description: `
        The second workshop on intellectual property was successfully conducted on 2nd November 2023 at SLTMobitel The Embryo Innovation Center, Trace City.
        The workshop was conducted by Mrs. Vindya Wijesinghe, senior innovation officer of National Innovation Agency together with Mrs. Thidasi Dahanayaka, senior innovation officer of National Innovation Agency. We want extend our heartfelt gratitude to Mrs. Vindya Wijesinghe and Mrs. Thidasi Dahanayaka for generously sharing their expertise and providing invaluable guidance.
        As The Embryo Innovation Centre, we are dedicated to fostering creativity and continually empowering our finalists by supporting and safeguarding their intellectual property rights through our program. Stay tuned with us for exciting updates on our future workshops and milestones of Entrepreneurship Studio Programme Cohort 2023/24.
      `,
      images: [
        require("../../../image/entraWorkshop2_1.jpg"),
        require("../../../image/entraWorkshop2_2.jpg"),
        require("../../../image/entraWorkshop2_3.jpg"),
        require("../../../image/entraWorkshop2_4.jpg"),
      ],
    },
    {
      title: "3rd Workshop: Product Development & Go to Market Strategy",
      date: "7th November 2023",
      description: `
        The Third workshop on Product development and Go to Market strategy was successfully conducted on 7th November 2023 at SLTMobitel The Embryo Innovation Centre, Trace City. 
        The workshop was conducted by Mr. Selvendiran Vidyapan, General Manager Enterprise and SME products of SLTMobitel. We want to extend our heartfelt gratitude to Mr. Selvendiran Vidyapan for generously sharing his expertise and providing valuable guidance.
        As the Embryo Innovation Centre, we are dedicated to educating and empowering our finalists by providing insights into product development and effective go-to-market strategies, enabling them to make informed decisions and enhance their business ventures. Stay tuned with us for exciting updates on our future workshops and milestones of Entrepreneurship Studio programme Cohort 2023/24.
      `,
      images: [
        require("../../../image/entraWorkshop3_1.jpg"),
        require("../../../image/entraWorkshop3_2.jpg"),
        require("../../../image/entraWorkshop3_3.jpg"),
        require("../../../image/entraWorkshop3_4.jpg"),
      ],
    },
    {
      title: "4th Workshop: Technology & Solution Architecting",
      date: "21st November 2023",
      description: `
        The Fourth workshop on Technology and Solution architecting was successfully conducted on 21st November 2023 at SLTMobitel The Embryo Innovation Centre, Trace City. 
        The workshop was conducted by Mr. Janaka Wikramasinghe, Head of Development at Ascension IT. We want to extend our heartfelt gratitude to Mr. Janaka Wikramasinghe for generously sharing his expertise and providing valuable guidance.
        As the Embryo Innovation Centre, we are dedicated to educating and empowering our finalists by providing insights into Technology and Solution architecting, enabling them to make informed decisions and enhance their business ventures. Stay tuned with us for exciting updates on our future workshops and milestones of the Entrepreneurship Studio program Cohort 2023/24.
      `,
      images: [
        require("../../../image/entraWorkshop4_1.jpg"),
        require("../../../image/entraWorkshop4_2.jpg"),
        require("../../../image/entraWorkshop4_3.jpg"),
        require("../../../image/entraWorkshop4_4.jpg"),
      ],
    },
    {
      title: "5th Workshop: Capital Funding & Investment",
      date: "7th December 2023",
      description: `
        The Fifth Workshop on Capital Funding and Investment was successfully conducted on December 7, 2023, at SLTMobitel, The Embryo Innovation Centre, Trace City. 
        The workshop was led by Mr. Mafaz Ishaq, Chief Executive Officer at Innovation Quotient (Pvt) Ltd. We extend our heartfelt gratitude to Mr. Mafaz Ishaq for generously sharing his expertise and providing valuable guidance.
        As the Embryo Innovation Centre, we are dedicated to educating and empowering our finalists by offering insights into Capital Funding and Investment, enabling them to make informed decisions and enhance their business ventures. Stay tuned with us for exciting updates on our Entrepreneurship Studio program Cohort 2023/24.
      `,
      images: [
        require("../../../image/entraWorkshop5_1.jpg"),
        require("../../../image/entraWorkshop5_2.jpg"),
        require("../../../image/entraWorkshop5_3.jpg"),
        require("../../../image/entraWorkshop5_4.jpg"),
      ],
    },
  ];

  const handlePrev = (index) => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage > 0
        ? prevSelectedImage - 1
        : workshops[index].images.length - 1
    );
  };

  const handleNext = (index) => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage < workshops[index].images.length - 1
        ? prevSelectedImage + 1
        : 0
    );
  };

  // Auto change effect
  useEffect(() => {
    const interval = setInterval(() => {
      workshops.forEach((_, index) => handleNextAutoChange(index));
    }, AUTO_CHANGE_INTERVAL);

    return () => clearInterval(interval);
  }, [selectedImage]);

  const handleNextAutoChange = (index) => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage < workshops[index].images.length - 1 ? prevSelectedImage + 1 : 0
    );
  };


  return (
    <div style={styles.container}>
      <Navbar />

      <div className="mt-5 my-5" style={styles.content}>
        <div style={styles.header}>
          <h1 className="pt-5 mt-5">Entrepreneurship Studio Program 2023</h1>
        </div>

        <div
          className="bgwhite rounded-5 pt-5 pb-4 mt-5"
          style={styles.workshopsContainer}
        >
          {workshops.map((workshop, index) => (
            <div
              key={index}
              style={{
                ...styles.section,
                flexDirection: isMobile
                  ? "column"
                  : index % 2 === 0
                    ? "row"
                    : "row-reverse",
              }}
            >
              <div className="description" style={styles.description}>
                <div style={styles.sectionTitle}>{workshop.title}</div>
                <p style={styles.paragraph}>
                  <strong>{workshop.date}</strong>
                </p>
                <p style={styles.paragraph}>{workshop.description}</p>
              </div>

              <div
                className="mainImageContainer"
                style={styles.mainImageContainer}
              >
                <img
                  src={workshop.images[selectedImage]}
                  alt={`Workshop highlight ${selectedImage + 1}`}
                  style={styles.mainImage}
                />
                <div
                  style={{ ...styles.prevNextButtons, ...styles.prevButton }}
                  onClick={() => handlePrev(index)}
                >
                  &lt;
                </div>
                <div
                  style={{ ...styles.prevNextButtons, ...styles.nextButton }}
                  onClick={() => handleNext(index)}
                >
                  &gt;
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Contact />
    </div>
  );
};
