import React, { useEffect, useState } from "react";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import emailjs from "@emailjs/browser";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './previousProgram.css';
import image from "../../../image/intraProgram.png"

// const newPath = window.location.pathname;
// if (newPath === "/intrapeneurship") {
//   fetch(
//     "https://api.countapi.xyz/update/159.223.218.132/9cf31bcf-21e5-4411-8189-a66d265434b6?amount=1"
//   );
// }

export const Intrapreneurship = () => {
  const [adminemail, setAdminemail] = useState([]);
  const [value, setValue] = useState("Intrapreneurship studio");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [tel, setTel] = useState();
  const [idea, setIdea] = useState();
  const [type, setType] = useState();
  const [desc, setDesc] = useState();
  const [file, setFile] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "intrapreneurship";

  const ImageAdd = (e) => {
    setFile(e.target.files[0]);
  };

  const navi = new useNavigate();

  useEffect(() => {
    try {
      axios.get("/api/intrapreneurshipadmin/get").then((res) => {
        setAdminemail(res.data);
      });
    } catch (err) {
      console.log(err);
    }

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);

  const arrytoobject = adminemail.map((u) => u.email);
  const emails = arrytoobject.join(" ");

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("tel", tel);
    formData.append("idea", idea);
    formData.append("desc", desc);
    formData.append("type", type);
    formData.append("file", file);

    setName("");
    setEmail("");
    setTel("");
    setIdea("");
    setType("");
    setDesc("");

    axios.post("/api/intrapreneurship/add", formData).then((res) => {
      console.log("sent");
      console.log(formData);
    });

    const templateParams = {
      name: name,
      email: email,
      tel: tel,
      type: "Interpreneureship",
      object: emails,
    };
    console.log(templateParams);

    emailjs
      .send(
        "service_sx17i5s",
        "template_ybem4ei",
        templateParams,
        "1OioyNoQgHrcuKdxN"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          alert("Send");
          window.location.reload();
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <div className="bggray">
      <Navbar />
      <div className="mt-5 my-5">
        <div className="text-center pt-5">
          <h1 className="pt-5 mt-5 ">Intrapreneurship Studio</h1>
        </div>
        <div className="bgwhite rounded-5 border-2 border-primary border-bottom border-top shadow pt-5 mx-3 pb-4 mt-5">
          <div className="" style={{ marginLeft: "15%", width: "70%" }}>
            <>
              SLT{" "}
              <strong className="text-primary">
                “Intrapreneurship Studio”
              </strong>{" "}
              program is created to support internal innovators and
              entrepreneurs to transform their ideas into groundbreaking new
              products and solutions. Innovative ideas are welcome from the
              employees who value collaborative efforts and who are willing to
              go the extra mile in their careers. We’ll be helping make your
              idea a success by leveraging SLT’s technologies and expertise. You
              are most welcome to share your innovative ideas and thoughts with
              the R&D team in respect to below three main R&D areas,
              <ul
                className="text-muted"
                style={{
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  marginTop: "20px",
                }}
              >
                <li>
                  <strong>Network and IT Infrastructure</strong>
                </li>
                <li>
                  <strong>Products and Solutions</strong>
                </li>
                <li>
                  <strong>Processes and OAM Practices</strong>
                </li>
              </ul>
              Your innovative idea is truly valuable to us, even you contribute
              only as an idea provider where such novel idea will be realized by
              the internally managed talent pool. Please submit your idea via
              the R&D web portal as given below. Your proposal will be evaluated
              and best realizable idea producers will be awarded via attractive
              rewarding schemes. As the starting step, Idea producers are
              initially encouraged to submit the ideas within two weeks of this
              invitation, which will be proceeded to the accelerator and the
              incubator stages. Further continuous idea generation is also
              welcome and those will be funneled to the accelerator and
              incubator stages periodically. Contributors will be duly
              recognized and rewarded in numerous ways as a formal career
              development process in the company.
              <p style={{ lineHeight: "1.6", color: "#333", fontWeight: "bold", marginTop: "20px" }}>
                Expertise Areas:
              </p>

              <ul
                className="text-muted"
                style={{
                  listStyleType: "circle",
                  paddingLeft: "20px"
                }}
              >
                <li>
                  <strong>Computer Vision</strong>
                </li>
                <li>
                  <strong>Data Analytics</strong>
                </li>
                <li>
                  <strong>API Development</strong>
                </li>
                <li>
                  <strong>Mixed Reality</strong>
                </li>
                <li>
                  <strong>Internet of Things (IoT)</strong>
                </li>
                <li>
                  <strong>App Development</strong>
                </li>
                <li>
                  <strong>Single board computer programming</strong>
                </li>
                <li>
                  <strong>IDE based programming</strong>
                </li>
                <li>
                  <strong>Networking</strong>
                </li>
                <li>
                  <strong>Cloud Infrastructure Architect</strong>
                </li>
                <li>
                  <strong>5G Technology</strong>
                </li>
                <li>
                  <strong>Electrical / Electronic</strong>
                </li>
              </ul>
            </>
            <div className="previous-programs-section mt-5">
              <h3 className="text-center">Previous Programs</h3>
              <div className="program-container mt-4" onClick={() => navi('/intraprogram')}>
                <div className="program-image">
                  <img
                    src={image}
                    alt="2023 Program"
                    className="img-fluid"
                    style={{ borderRadius: '15px', width: '100%', objectFit: 'cover', height: '200px' }}
                  />
                  <div className="program-description mt-3">
                    <h4 className="text-center">2023 Program </h4>
                  </div>
                </div>

              </div>
            </div>
          </div>

          {/* form */}
          <div
            className="border-3 border-primary border-top border-left rounded-4 shadow my-5 pb-3 py-4 bg-light "
            style={{
              width: "85%",
              paddingLeft: "8%",
              paddingRight: "8%",
              marginLeft: "7.5%",
            }}
          >
            <div className="text-center">
              <h3>Submit Your Ideas</h3>
            </div>
            <form
              className="mt-5"
              onSubmit={sendEmail}
              encType="multipart/form-data"
            >
              <div className="form-group">
                <label for="Name">Name</label>
                <input
                  type="name"
                  className="form-control"
                  id="Name"
                  value={name}
                  name={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                  required
                />
              </div>
              <div className="form-group">
                <label for="Email1">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="Email1"
                  value={email}
                  name={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  required
                />
              </div>
              <div className="form-group">
                <PhoneInput
                  className="form-control"
                  placeholder="Enter phone number"
                  defaultCountry="LK"
                  international
                  name={tel}
                  value={tel}
                  onChange={setTel}
                  required
                />
              </div>
              <div className="form-group">
                <label for="">Idea Title</label>
                <input
                  type=""
                  className="form-control"
                  id=""
                  onChange={(e) => setIdea(e.target.value)}
                  value={idea}
                  name={idea}
                  placeholder="Idea Title"
                  required
                />
              </div>

              {/* radio */}

              <div className="my-5">
                <label for="Email1">Related Field</label>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Agriculture"
                    onChange={(e) => setType(e.target.value)}
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Agriculture
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios3"
                    value="Education"
                    onChange={(e) => setType(e.target.value)}
                  />
                  <label className="form-check-label" for="exampleRadios3">
                    Education
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Transport "
                    onChange={(e) => setType(e.target.value)}
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Transport
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Fintech"
                    onChange={(e) => setType(e.target.value)}
                    required
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Fintech
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="SmartCity"
                    onChange={(e) => setType(e.target.value)}
                    required
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Smart City
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="SmartGovernment"
                    onChange={(e) => setType(e.target.value)}
                    required
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Smart Government
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Network&IT"
                    onChange={(e) => setType(e.target.value)}
                    required
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Network & IT
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="ProcessAutomation"
                    onChange={(e) => setType(e.target.value)}
                    required
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Process Automation
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="CustomerExperience"
                    onChange={(e) => setType(e.target.value)}
                    required
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Customer Experience
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="Other"
                    onChange={(e) => setType(e.target.value)}
                    required
                  />
                  <label className="form-check-label" for="exampleRadios1">
                    Other
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label for="">Description</label>
                <input
                  type=""
                  className="form-control"
                  id=""
                  onChange={(e) => setDesc(e.target.value)}
                  placeholder="Description"
                  required
                />
              </div>

              <div className="form-group">
                <label for="Email1">
                  Any Relevant Attachments In PDF (Max 4MB)
                </label>
                <input
                  type="file"
                  multiple
                  filename="file"
                  onChange={ImageAdd}
                  className="form-input"
                  required
                />
              </div>

              <div className="text-center">
                <button type="submit" className="btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};
