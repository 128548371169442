import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";
import { Contact } from "../home/Contact";

// const newPath = window.location.pathname;
// if (newPath === "/innovation") {
//   fetch(
//     "https://api.countapi.xyz/update/159.223.218.132/b513d986-343b-4cd1-8c44-3368b16e7be0?amount=1"
//   );
// }

export const Innovation = () => {
  const [item, setItem] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "innovation";

  useEffect(() => {
    try {
      axios.get("/api/innovation/get").then((res) => {
        setItem(res.data);
      });
    } catch (err) {
      console.log(err);
    }

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);

  return (
    <div className="bggray">
      <Navbar className="" />
      <div className="mt-5 my-5">
        <div className="">
          <br></br>
          <div className="text-center my-5">
            <h1>Innovation</h1>
          </div>
          {item.map((items) => {
            return (
              <section
                className=" py-2 mt-3 bg-light shadow rounded-2"
                id="scroll-target"
                style={{ marginLeft: "5%", marginRight: "5%" }}
              >
                <div className="container px-5 my-3">
                  <div className="row gx-5 align-items-center">
                    <div className="col-lg-5">
                      <img
                        className="img-fluid rounded mb-5 mb-lg-0 "
                        src={"/upload/" + items.image}
                        alt="..."
                      />
                    </div>
                    <div className="col-lg-6">
                      <h3 className="fw-bolder">{items.name}</h3>
                      <p className="lead fw-normal  mb-0 text-justify">
                        {items.desc}
                      </p>
                      <Link to={`/innovationdetails/${items._id}`}>
                        <button className="btn btn-primary">More</button>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
              </section>
            );
          })}
        </div>
      </div>
      <Contact />
    </div>
  );
};
