import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../../style/css/admin/User.css";
import { Navbar } from "../../../commn/Navbar.js";
import { Link, useNavigate } from "react-router-dom";
import { InternshipVideoUpdate } from "./InternshipVideoUpdate.js";

export const InternshipVideoDisplay = () => {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      axios.get("/api/internshipvideo/get").then((res) => {
        setData(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // const Deleteadmin = (id) => {
  //   axios
  //     .delete(`/api/internshipvideo/delete/${id}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization:
  //           "Bearer " + JSON.parse(localStorage.getItem("user")).token,
  //       },
  //     })
  //     .then((res) => {
  //       alert("Internship video has been deleted");
  //       navigate("/Ainternshipvideodisplay");
  //     })
  //     .catch((err) => {
  //       console.error("Error deleting video: ", err);
  //     });
  // };
  const Deleteadmin = (id) => {
    axios
      .delete(`/api/internshipvideo/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        console.log(res); // Log the response to see what the server returns
        if (res.status === 200) {
          alert("Internship video has been deleted");
          // Reload the data after deletion
          axios.get("/api/internshipvideo/get").then((res) => {
            setData(res.data);
          });
        } else {
          alert("Failed to delete the video");
        }
      })
      .catch((error) => {
        console.error("Error deleting video: ", error);
      });
  };

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="container">
          <Link to="/Ainternship">
            <button className="btn btn-primary mt-5">Intern Details</button>
          </Link>
          <Link to="/Ainternshipvideoadd">
            <button className="btn btn-primary mt-5">
              Add Internship Video
            </button>
          </Link>
        </div>
        <div className="td">
          <h3>Internship Videos</h3>
          <table className="table shadow container" style={{ width: "70%" }}>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">URL</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((video) => (
                <tr key={video._id}>
                  <td>{video.name}</td>
                  <td>{video.url}</td>
                  <td>
                    <Link to={`/InternshipVideoUpdate/${video._id}`}>
                      <button className="btn">Update</button>
                    </Link>
                    <button
                      className="btn"
                      onClick={() => {
                        Deleteadmin(video._id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
