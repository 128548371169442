import React from "react";
import "./contactmain.css";
import { FaEnvelope, FaLocationArrow, FaPhone } from "react-icons/fa";

export const Contact = () => {
  const year = () => {
    return;
  };
  return (
    <div>
      <div className="row gx-3 row-cols-1 row-cols-md-3 bggray2 rounded shadow contact-m-block">
        <div className="col mb-5 h-100 px-3 contact-box-1">
          <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
            <i className="bi bi-building"></i>
          </div>
          <h2 className="h5 contact-box text-white">Contact US</h2>
          <p className="mb-0 text-justify contact-p-box p-box-font text-white">
            <FaLocationArrow size="0.9em" />
            &nbsp; P O Box 503, Lotus Rd,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Colombo 01,
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sri Lanka <br />
            <FaEnvelope size="0.9em" />
            &nbsp; info@sltdigitallab.lk <br />
            <FaPhone size="0.9em" />
            &nbsp; +94112430010 
            <br />
           
          </p>
        </div>
      </div>

      {/* <div className='pt-3 pb-3 mx-5 bggray2 rounded shadow contact-m-block' >
            <h1 className='ml-5 text-white'>Contact US</h1>
            <div>
                <></>
                <h6 className='ml-5 text-white'>P O Box 503, Lotus Rd,Colombo 01,Sri Lanka</h6>
            </div>
            <div>
                <></>
                <h6 className='ml-5 text-white'>info@sltdigitallab.lk</h6>
            </div>
            <div>
                <></>
                <h6 className='ml-5 text-white'>0112430010</h6>
            </div>
            <hr></hr>
        </div> */}
      <div className="container text-center">
        <p className = "footer-text">
          Copyright © <>{new Date().getFullYear()}</> Research & Development Section of Sri Lanka Telecom PLC |
          Powered by SLT Digital Lab
        </p>
      </div>
      <hr />
    </div>
  );
};
