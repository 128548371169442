import React, { useState } from "react";
import axios from "axios";
import Wrapper from "../../style/wrappers/RegisterPage";
import { Navbar } from "../../commn/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cadmin, setCAdmin] = useState("");
  const [password, setPassword] = useState("");
  const [rpassword, setRpassword] = useState("");

  const navigate = useNavigate();

  const Submit = async (e) => {
    e.preventDefault();

    if (password !== rpassword) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "password does not match!!!",
      });
    } else {
      const newuser = {
        name,
        email,
        password,
        cadmin,
      };

      try {
        const token = JSON.parse(localStorage.getItem("user")).token;

        const res = await axios.post("/api/user/reg", newuser, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        Swal.fire("Congrats!", "Successfully Registered", "success");
        navigate("/user");
      } catch (error) {
        console.error("Registration error: ", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Registration failed!",
        });
      }
    }
  };

  return (
    <div>
      <Navbar />
      <div>
        <Wrapper className="full-page mt-">
          <div className="text-center mt-3">
            <Link to="/user">
              <button className="btn btn-primary">User Details</button>
            </Link>
          </div>
          <form className="form" onSubmit={Submit}>
            <h3>Add Users</h3>
            {/* name */}
            <div className="form-row">
              <label className="form-label">Name</label>
              <input
                type="text"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                className="form-input"
              />
            </div>
            {/* email */}
            <div className="form-row">
              <label className="form-label">Email</label>
              <input
                type="email"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-input"
              />
            </div>
            {/* user */}
            <div>
              <label className="form-label">User</label>
              <select
                className="form-input"
                value={cadmin}
                name="cadmin"
                onChange={(e) => setCAdmin(e.target.value)}
              >
                <option value="">choose</option>
                <option value="Admin">Admin</option>
                <option value="Cadmin">Core-Admin</option>
              </select>
            </div>
            {/* password */}
            <div className="form-row">
              <label className="form-label">Password</label>
              <input
                type="password"
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-input"
              />
            </div>
            <div className="form-row">
              <label className="form-label">ReEnter Password</label>
              <input
                type="password"
                value={rpassword}
                name="rpassword"
                onChange={(e) => setRpassword(e.target.value)}
                className="form-input"
              />
            </div>
            <button type="submit" className="btn btn-block">
              Submit
            </button>
          </form>
        </Wrapper>
      </div>
    </div>
  );
};

export default Register;
