import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";

export const BlogsMessageAllAdmin = () => {
  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <Link to={"/Ablogsmsg"}>
          <button type="button" className="btn btn-primary mt-5">
            Waitting Message
          </button>
        </Link>
        <Link to={"/AblogsNot"}>
          <button type="button" className="btn btn-primary mt-5">
            Not Approvaled Message
          </button>
        </Link>
        <Link to={"/AblogsApproval"}>
          <button type="button" className="btn btn-primary mt-5">
            Approvaled Message
          </button>
        </Link>
      </div>
      <div>BlogsMessageAllAdmin</div>
    </div>
  );
};
