// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { Navbar } from "../../../commn/Navbar";
// import { Contact } from "../../home/Contact";
// export const NewsMore = () => {
//   const [item, setItem] = useState([]);
//   const [name, setName] = useState();
//   const [date, setDate] = useState();
//   const [uploadername, setUploader] = useState();
//   const [shortdesc, setDesc] = useState();
//   const [longdesc, setLongdesc] = useState();
//   const [image, setImage] = useState();
//   const [mname, setMName] = useState();
//   const [memail, setMemail] = useState();
//   const [mblogname, setMblogname] = useState();
//   const [filterblogname, setFilterblogname] = useState();
//   const [message, setMessage] = useState();
//   const [mwebsite, setMwebsite] = useState();

//   const params = useParams();

//   const id = params.id;

//   const getdata = () => {
//     axios.get(`/api/newsevent/get/${id}`).then((res) => {
//       setName(res.data.name);
//       setDate(res.data.date);
//       setUploader(res.data.uploadername);
//       setDesc(res.data.shortdesc);
//       setLongdesc(res.data.longdesc);
//       setImage(res.data.image);
//       setMblogname(res.data.name);
//       setFilterblogname(res.data.name);
//     });
//   };

//   useEffect(() => {
//     getdata();
//   }, []);

//   return (
//     <div className="bggray">
//       <Navbar />
//       <div className="mt-5 my-5">
//         <div className="text-center mt-5 pt-5">
//           <h1 className="fw-bolder">{name}</h1>
//         </div>
//         <section
//           className=" py-3 mx-2 mt-5 bg-light shadow rounded-2"
//           id="scroll-target"
//         >
//           <div className="container my-3">
//             <div className="row  justify-content-center text-center">
//               <div className="col-lg-6">
//                 <div className="">
//                   <img
//                     className=" rounded mb-5 mb-lg-0"
//                     style={{ width: "90%", height: "(90%" }}
//                     src={"/newsevent/" + image}
//                     alt="..."
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="mt-5">
//               <div className="row mx-5 mb-3 text-center">
//                 <h6 className="fw-normal text-muted mb-0">
//                   Publish by : {uploadername}
//                 </h6>
//                 <h6 className="fw-normal text-muted mb-0 ml-"> {date}</h6>
//               </div>
//               <div className="row mx-5">
//                 <div className="col-md-6 mt-1">
//                   <p className=" fw-normal text-muted mb-0 text-justify">
//                     {shortdesc}
//                   </p>
//                 </div>
//                 <div className="col-md-6 mt-1">
//                   <p className=" fw-normal text-muted mb-0 text-justify">
//                     {longdesc}
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//       <Contact />
//     </div>
//   );
// };

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is included

export const NewsMore = () => {
  const [item, setItem] = useState({});
  const [images, setImages] = useState([]);

  const params = useParams();
  const id = params.id;

  useEffect(() => {
    axios.get(`/api/newsevent/get/${id}`).then((res) => {
      setItem(res.data);
      setImages(res.data.images); // Assuming 'images' is an array
    });
  }, [id]);

  return (
    <div className="bggray">
      <Navbar />
      <div className="mt-5 my-5">
        <div className="text-center mt-5 pt-5">
          <h1 className="fw-bolder">{item.name}</h1>
        </div>
        <section
          className=" py-3 mx-2 mt-5 bg-light shadow rounded-2"
          id="scroll-target"
        >
          <div className="container my-3">
            <div className="row justify-content-center text-center">
              <div className="col-lg-8">
                {/* Bootstrap Carousel */}
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {images.map((img, index) => (
                      <div
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                        key={index}
                      >
                        <img
                          src={`/newsevent/${img}`}
                          className="d-block w-100"
                          alt="..."
                          style={{ maxHeight: "500px", objectFit: "cover" }}
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="row mx-5 mb-3 text-center">
                <h6 className="fw-normal text-muted mb-0">
                  Published by: {item.uploadername}
                </h6>
                <h6 className="fw-normal text-muted mb-0 ml-"> {item.date}</h6>
              </div>
              <div className="row mx-5">
                <div className="col-md-6 mt-1">
                  <p className="fw-normal text-muted mb-0 text-justify">
                    {item.shortdesc}
                  </p>
                </div>
                <div className="col-md-6 mt-1">
                  <p className="fw-normal text-muted mb-0 text-justify">
                    {item.longdesc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Contact />
    </div>
  );
};
