import React, { useState } from "react";
import axios from "axios";
import Wrapper from "../../../style/wrappers/RegisterPage.js";
import { Navbar } from "../../../commn/Navbar.js";
import { Link, useNavigate } from "react-router-dom";

export const Intrapreneurshipemail = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navi = useNavigate();

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!name || !email) {
      setError("Both fields are required.");
      return;
    }

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    const newadmin = { name, email };

    try {
      const token = JSON.parse(localStorage.getItem("user"))?.token;

      if (!token) {
        throw new Error("User token not found");
      }

      const newadmin = { name, email };

      const response = await axios.post(
        "/api/intrapreneurshipadmin/add",
        newadmin,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
      alert("Successfully Added");
      navi("/Aintraemail"); // Navigate to desired route upon successful submission
    } catch (error) {
      console.error("Error adding Intrapreneurship admin:", error);
      alert("Failed to add Intrapreneurship admin.");
    }
  };

  return (
    <div>
      <Navbar />
      <Wrapper className="">
        <div className="text-center">
          <Link to="/Aintraemail">
            <button className="btn btn-primary">Internship Emails</button>
          </Link>
        </div>
        <form
          className="form"
          onSubmit={handleSubmit}
          style={{ marginTop: "5%" }}
        >
          <h3>Add Intrapreneurship Email</h3>
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
              required
            />
          </div>
          <div className="form-row">
            <label className="form-label">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              required
            />
          </div>
          <button type="submit" className="btn btn-block">
            Submit
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
