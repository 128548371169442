import React, { useState } from "react";
import axios from "axios";
import Wrapper from "../../../style/wrappers/RegisterPage.js";
import { Navbar } from "../../../commn/Navbar.js";
import { Link, useNavigate } from "react-router-dom";

export const Internshipemail = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    const newadmin = {
      name,
      email,
    };

    axios
      .post("/api/internshipadmin/add", newadmin, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        alert("Successfully Added");
        navigate("/Ainternshipemail");
      })
      .catch((err) => {
        console.error("Error adding internship admin:", err);
        alert("Failed to add internship admin");
      });
  };

  return (
    <div>
      <Navbar />
      <Wrapper className=" mt-5">
        <div className="text-center">
          <Link to="/Ainternshipemail">
            <button className="btn btn-primary">Internship Emails</button>
          </Link>
        </div>
        <form
          className="form"
          onSubmit={handleSubmit}
          style={{ marginTop: "5%" }}
        >
          <h3>Add Internship Email</h3>
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Email</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
            />
          </div>
          <button type="submit" className="btn btn-block">
            Submit
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
