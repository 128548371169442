import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";

export const DisplayBlogs = () => {
  const [items, setItem] = useState([]);

  const navi = new useNavigate();

  useEffect(() => {
    try {
      axios.get("/api/blogs/get").then((res) => {
        setItem(res.data);
      });
    } catch (err) {
      console.log(err);
    }
    //   console.log('node men concel beg and t')
  });

  //delete user
  const Deleteinnovation = (id) => {
    axios.delete(`/api/blogs/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    alert("Delete Blogs");
    navi("/Ablogsdisplay");
  };

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="mt-5" style={{ textAlign: "center" }}>
          <Link to={"/Ablogs"}>
            <button type="button" className="btn btn-primary mt-5">
              ADD New Blogs
            </button>
          </Link>
          <Link to={"/Ablogspdfnotadd"}>
            <button type="button" className="btn btn-primary mt-5">
              Blogs Document
            </button>
          </Link>
        </div>
        <div className="mt-5">
          <div>
            <h2>Blogs</h2>
          </div>
          <table
            className="table shadow"
            style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
          >
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Uploader Name</th>
                <th scope="col">Upload Date</th>
                <th scope="col">Blog Name</th>
                <th scope="col" style={{ width: "25%" }}>
                  Short Description
                </th>
                <th scope="col" style={{ width: "25%" }}>
                  Description
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {items.map((blogs) => {
                return (
                  <tr>
                    <td>
                      <>
                        <img
                          className="rounded "
                          style={{ width: "100%", height: "1000%" }}
                          src={"/blogs/" + blogs.image}
                        />
                      </>
                    </td>
                    <td>{blogs.uploadername}</td>
                    <td>{blogs.date}</td>
                    <td>{blogs.name}</td>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={8}
                        cols-xs-10
                        type="desc"
                        disabled
                      >
                        {blogs.shortdesc}
                      </textarea>
                    </td>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={8}
                        cols-xs-10
                        cols={20}
                        type="desc"
                        disabled
                      >
                        {blogs.longdesc}
                      </textarea>
                    </td>
                    {/* <td>{innovation.longdesc}</td> */}
                    <td>
                      <Link to={`/updateblog/${blogs._id}`}>
                        <button className="btn">Update</button>
                      </Link>

                      <button
                        className="btn"
                        onClick={() => {
                          Deleteinnovation(blogs._id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
