import axios from "axios";
import React, { useState, useEffect } from "react";
import "./product.css";

export const HomeNews = () => {
  const [item, setItem] = useState([]);

  useEffect(() => {
    axios
      .get("/api/newsevent/getlast")
      .then((res) => {
        console.log("Data fetched:", res.data); // You can log the response data to confirm
        setItem(res.data); // Set the response data to your state
      })
      .catch((err) => {
        console.error("Error fetching data:", err); // Log any errors in fetching
      });
  }, []);

  return (
    <div>
      <section className="py-">
        <div className="container incontainer my-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <div className="text-center ino-box">
                <h2 className="fw-bolder">Latest News</h2>
              </div>
            </div>
          </div>
          <div className="vline"></div>
          <div className="row gx-1 mx-0 px-0 row-cols-1">
            {item.map((news) => {
              return (
                <div className="row gx-4 innov-block" key={news._id}>
                  <div className="col-lg- mb-5 mx-3">
                    <div className="card border-0 px-2">
                      <div className="row">
                        <div className="col-md-8 mb-3 card-body text-justify p-4">
                          <a
                            className="text-decoration-none link-dark stretched-link"
                            href={`/news/${news._id}`}
                          >
                            <h5 className="card-title my-3 mb-3">
                              {news.name}
                            </h5>
                          </a>
                          <div className="hline"></div>
                          <p className="card-text mb-0 ml-3 c-text-align">
                            {news.shortdesc}
                          </p>
                        </div>
                        <div className="col-md-4 p-4 text-center align-self-center">
                          {news.images && news.images.length > 0 ? (
                            <img
                              src={`/newsevent/${news.images[0]
                                }`} // Display the first image
                              alt="..."
                              className="img-fluid rounded mx-auto"
                            />
                          ) : (
                            <span>No image available</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};
