import React, { useEffect, useState } from "react";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import "./Internship.css";
import ReactPlayer from "react-player";
import axios from "axios";

export const InternshipForm = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState(null);
  const [error, setError] = useState(null);
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "internship";

  useEffect(() => {
    // const googleDriveLink = {
    //   url: "https://drive.google.com/file/d/1XxC58wJ5GpKht4HD-TENMX6payeNqmST/preview",
    // };

    // setVideo(googleDriveLink);

    // Fetch the video data from the API
    const fetchRandomVideo = async () => {
      try {
        const response = await axios.get("/api/internshipvideo/random");
        setData(response.data); // Set the fetched data
        setLoading(false); // Stop loading once data is fetched
      } catch (error) {
        console.error("Error fetching video data:", error);
        setLoading(false); // Stop loading even if there's an error
      }
    };
    fetchRandomVideo();

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);

  // Check if the video data exists and extract the URL
  const videoUrl = data?.url;

  return (
    <div className="bggray">
      <Navbar />
      <div className="mt-5 my-5">
        <div className="text-center pt-5">
          <h1 className="pt-5 mt-5">Apply for Internship</h1>
        </div>
        <div
          className="bgwhite rounded-5 border-2 border-primary border-bottom border-top shadow pt-5 pb-4 mt-5"
          style={{ marginLeft: "3%", marginRight: "3%" }}
        >
          <div style={{ marginLeft: "20%", width: "60%", marginRight: "20%" }}>
            <div>
              <div className="text-center">
                <h3 className="mb-4" style={{ color: "#2007ab" }}>
                  Apply for Your Internship With SLT Digital Lab
                </h3>
                <h5 className="mb-4" style={{ color: "#2007ab" }}>
                  Job Description
                </h5>
              </div>
              Are you a student looking for an Internship in Research and
              Development? Are you looking for an internship that will offer you
              an assignment in one of our running projects and that will
              challenge you with a wide range of work and opportunities?
              <br /> <br /> If you are, keep reading…
              <br /> <br /> We pride ourselves on being able to offer interns a
              challenging experience in our R&D initiatives.
              <br /> <br /> In your role as an R&D intern, you will be given
              full responsibility and accountability for projects from the
              beginning of your placement, and you will have an opportunity to
              fully integrate and make a difference. We offer internships in
              different expertise areas within Research & Development (R&D) like
              IoT, AI/ML/DL, 5G, Network Softwareization, and programming.
              <br />
              <div className="text-center">
                <h5 className="mb-4 mt-5" style={{ color: "#2007ab" }}>
                  Qualifications
                </h5>
              </div>
              To be successful in this role, you need to be enrolled at a
              University for the whole duration of the internship, preferably in
              one of the following degrees:
              <br />
              <div className="ml-5">
                <ul className="text-muted" style={{ listStyleType: "disc" }}>
                  <li>Electronic/Computer Engineering</li>
                  <li>Computer Science</li>
                </ul>
              </div>

              {/* Video section with error handling and loading state */}
              <div className="text-center mt-4">
                {loading ? (
                  <div>Loading video...</div>
                ) : videoUrl ? (
                  <ReactPlayer
                    url={videoUrl}
                    controls
                    playing
                    width="100%"
                    height="480px"
                    loop
                    muted
                  />
                ) : (
                  <div>No video available</div>
                )

              /* {video ? (
                <div
                  className="video-wrapper text-center"
                  style={{ marginTop: "0" }}
                >
                  <div>{video.name}</div>
                  <iframe
                    src={video.url}
                    width="100%"
                    height="480"
                    allow="autoplay"
                    allowFullScreen
                    style={{ marginTop: "0" }}
                  ></iframe>
                </div>
              ) : (
                <div>{error || "Loading video..."}</div>
              )} */}
              </div>
              <p>If you are the right person, apply now!</p>
            </div>
          </div>

          {/* form */}
          <div className="form-container text-center">
            <iframe
              className="container-web"
              src="https://forms.office.com/Pages/ResponsePage.aspx?id=_FNCU7bfL0a1ysvoGTn17kKEFSRjDXdOt7n1tjxTAKNURVNZN01QRE1YWlZQNkJCTlRPREtRQTk0My4u&embed=true"
              style={{ width: "60%", height: "100vh", marginLeft: "20%" }}
              allowFullScreen
            />
            <iframe
              className="container-mobile"
              src="https://forms.office.com/Pages/ResponsePage.aspx?id=_FNCU7bfL0a1ysvoGTn17kKEFSRjDXdOt7n1tjxTAKNURVNZN01QRE1YWlZQNkJCTlRPREtRQTk0My4u&embed=true"
              style={{ width: "80%", height: "100vh", marginLeft: "5%" }}
              allowFullScreen
            />
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};
