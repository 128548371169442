import axios from "axios";
import React, { useState } from "react";
import "./product.css";
import "./Object.css";

export const Product = () => {
  const [item, setItem] = useState([]);

  useState(() => {
    axios.get("/api/innovation/getlast").then((res) => {
      setItem(res.data);
    });
  });

  return (
    <div>
      <section className="py-5">
        <div className="container incontainer my-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <div className="text-center ino-box">
                <h2 className="fw-bolder">Innovation</h2>
              </div>
            </div>
          </div>
          <div className="vline"></div>
          <div className="row gx-1 mx-0 px-0 row-cols-1">
            {item.map((datas) => {
              return (
                <div className="row gx-4 innov-block">
                  <div className="col-lg- mb-5 mx-3">
                    <div className="card border-0 px-2">
                      <div className="row">
                        <div className="col-md-8 mb-3 card-body text-justify p-4">
                          <a
                            className="text-decoration-none link-dark stretched-link"
                            href={`/innovationdetails/${datas._id}`}
                          >
                            <h5 className="card-title my-3 mb-3 card-b">
                              {datas.name}
                            </h5>
                          </a>
                          <div className="hline"></div>
                          <p className="card-text mb-0 ml-3 c-text-align">
                            {datas.desc}
                          </p>
                        </div>
                        <div className="col-md-4 p-4 text-center align-self-center">
                          <img
                            src={"/upload/" + datas.image}
                            alt="..."
                            className="img-fluid rounded  mx-auto"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};
