import React, { useEffect, useState } from "react";
import axios from "axios";
import Wrapper from "../../style/wrappers/RegisterPage";
import { Navbar } from "../../commn/Navbar";
import { useNavigate, useParams, Link } from "react-router-dom";

export const TeamUpdate = () => {
  const [no, setNo] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");

  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL

  useEffect(() => {
    // Fetch the existing details of the user to pre-fill the form
    axios
      .get(`/api/team/get/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        console.log("Fetched data:", res.data);
        setNo(res.data.no);
        setImage(res.data.image);
        setUrl(res.data.url);
        setName(res.data.name);
        setDetails(res.data.details);
      })
      .catch((err) => console.log("Error fetching data:", err));
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      await axios.put(
        `/api/team/update/${id}`,
        { no, image, url, name, details },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );
      alert("Updated team");
      navigate("/Ateam");
    } catch (error) {
      console.error("Error updating team:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Wrapper className="mt-5">
        <div className="text-center">
          <Link to="/Ainternshipemail">
            <button className="btn btn-primary">
              Back to Internship Emails
            </button>
          </Link>
        </div>
        <form
          className="form"
          onSubmit={handleUpdate}
          style={{ marginTop: "5%" }}
        >
          <h3>Update Team</h3>
          <div className="form-row">
            <label className="form-label">No</label>
            <input
              type="text"
              value={no}
              onChange={(e) => setNo(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Image</label>
            <input
              type="file"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Url</label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Details</label>
            <input
              type="text"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              className="form-input"
            />
          </div>
          <button type="submit" className="btn btn-block">
            Update
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
