import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";
import Wrapper from "../../style/wrappers/RegisterPage";

export const UpadateBlogs = () => {
  const [name, setName] = useState();
  const [uploadername, setUploadername] = useState();
  const [date, setDate] = useState();
  const [shortdesc, setShortdesc] = useState();
  const [longdesc, setLongdesc] = useState();

  const params = useParams();
  const navi = new useNavigate();

  useEffect(() => {
    axios.get(`/api/blogs/get/${params.id}`).then((res) => {
      setName(res.data.name);
      setUploadername(res.data.uploadername);
      setDate(res.data.date);
      setShortdesc(res.data.shortdesc);
      setLongdesc(res.data.longdesc);
    });
  }, []);

  const data = {
    name,
    uploadername,
    date,
    shortdesc,
    longdesc,
  };

  const Update = () => {
    axios.put(`/api/blogs/update/${params.id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    alert("Updated Blogs");
    navi("/Ablogsdisplay");
  };

  return (
    <div>
      <Navbar />
      <div className="" style={{ textAlign: "center", marginTop: "5%" }}>
        <Link to={"/Ablogsdisplay"}>
          <button type="button" className="btn btn-primary">
            Blogs
          </button>
        </Link>
        <Link to={"/Ablogs"}>
          <button type="button" className="btn btn-primary">
            ADD New Blogs
          </button>
        </Link>
      </div>
      <Wrapper className="full-page">
        <form className="form" onSubmit={Update}>
          <h3>Update News/Events</h3>
          {/* name */}
          <div className="form-row">
            <label className="form-label">News/Events Name</label>
            <input
              type="text"
              value={name}
              name={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Uploader Name</label>
            <input
              type="text"
              value={uploadername}
              name={uploadername}
              onChange={(e) => setUploadername(e.target.value)}
              className="form-input"
              disabled
            />
          </div>
          <div className="form-row">
            <label className="form-label">Upload Date</label>
            <input
              type="text"
              value={date}
              name={date}
              onChange={(e) => setDate(e.target.value)}
              className="form-input"
              disabled
            />
          </div>
          {/* SHort Description */}
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
              {" "}
              Short Description
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={10}
              type="desc"
              value={shortdesc}
              name={shortdesc}
              onChange={(e) => setShortdesc(e.target.value)}
            />
          </div>

          {/*  Description */}
          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">Description</label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              type="desc"
              rows="10"
              value={longdesc}
              name={longdesc}
              onChange={(e) => setLongdesc(e.target.value)}
            />
          </div>

          <button type="submit" className="btn btn-block">
            Update
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
