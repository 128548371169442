import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../../../commn/Navbar";

export const EntrepreneurshipDetails = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [businessname, setBusinessname] = useState("");
  const [businessaddress, setBusinessaddress] = useState("");
  const [registedbusiness, setRegistedbusiness] = useState("");
  const [businesstype, setBusinesstype] = useState("");
  const [productdetails, setProductdetails] = useState("");
  const [productsachivecustomerdetails, setProductsachivecustomerdetails] =
    useState("");
  const [what_industry_customer_oprater, setWhat_industry_customer_oprater] =
    useState("");
  const [uniquesolution, setUniquesolution] = useState("");
  const [businesscompetition, setBusinesscompetition] = useState("");
  const [planningearn, setPlanningearn] = useState("");
  const [ispayingcustomer, setIspayingcustomer] = useState("");
  const [websitename, setWebsitename] = useState("");
  const [how_rank_product, setHow_rank_product] = useState("");
  const [how_old_startup, setHow_old_startup] = useState("");
  const [how_people_in_startup, setHow_people_in_startup] = useState("");
  const [who_found_of_startup, setWho_found_of_startup] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [describe_birth_startup, setDescribe_birth_startup] = useState("");
  const [how_found_startup, setHow_found_startup] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [work_tel, setWork_tel] = useState("");
  const [other, setOther] = useState("");
  const [expectations_details, setExpectations_details] = useState("");
  const [file, setFile] = useState([]);

  const params = useParams();

  const id = params.id;

  console.log(id);

  useEffect(() => {
    axios.get(`/api/entrepreneurship/get/${id}`).then((res) => {
      setName(res.data.name);
      setAddress(res.data.address);
      setBusinessname(res.data.businessname);
      setBusinessaddress(res.data.businessaddress);
      setRegistedbusiness(res.data.registedbusiness);
      setBusinesstype(res.data.businesstype);
      setProductdetails(res.data.productdetails);
      setProductsachivecustomerdetails(res.data.productsachivecustomerdetails);
      setWhat_industry_customer_oprater(
        res.data.what_industry_customer_oprater
      );
      setUniquesolution(res.data.uniquesolution);
      setBusinesscompetition(res.data.businesscompetition);
      setPlanningearn(res.data.planningearn);
      setIspayingcustomer(res.data.ispayingcustomer);
      setWebsitename(res.data.websitename);
      setHow_rank_product(res.data.how_rank_product);
      setHow_old_startup(res.data.how_old_startup);
      setHow_people_in_startup(res.data.how_people_in_startup);
      setWho_found_of_startup(res.data.who_found_of_startup);
      setLinkedin(res.data.linkedin);
      setDescribe_birth_startup(res.data.describe_birth_startup);
      setHow_found_startup(res.data.how_found_startup);
      setEmail(res.data.email);
      setTel(res.data.tel);
      setWork_tel(res.data.work_tel);
      setOther(res.data.other);
      setExpectations_details(res.data.expectations_details);
    });
  }, []);

  return (
    <div>
      <Navbar />

      <div className="dbtn text-center">
        <Link to="/Aentraemail">
          <button className="btn btn-primary mt-5">
            Entrepreneurship Email
          </button>
        </Link>
      </div>
      {/* form */}
      <div
        className="container border-3 border-primary border-top border-left rounded-3 shadow px-4 mb-5 pb-3 py-4 bg-light "
        style={{ width: "75%", marginTop: "5%", marginLeft: "" }}
      >
        <div className="text-center">
          <h2>SLT Entrepreneurship Studio</h2>
        </div>
        <form className="mt-5">
          {/* name */}
          <div className="form-group">
            <label for="Name"> Name </label>
            <input className="form-control" value={name} disabled />
          </div>

          {/* address */}
          <div className="form-group">
            <label for="">Address</label>
            <textarea
              className="form-control"
              value={address}
              rows="2"
              disabled
            />
          </div>

          {/* businessname */}
          <div className="form-group">
            <label for=""> Name of Business </label>
            <input className="form-control" value={businessname} disabled />
          </div>

          {/* businessaddress */}
          <div className="form-group">
            <label for=""> Address of the Business</label>
            <textarea
              className="form-control"
              value={businessaddress}
              rows="3"
              disabled
            />
          </div>

          {/* registedbusiness */}
          <div className="form-group">
            <label for="">Business Registered status</label>
            <input className="form-control" value={registedbusiness} disabled />
          </div>

          {/* businesstype */}
          <div className="form-group">
            <label for="">Business type </label>
            <br />
            <input className="form-control" value={businesstype} disabled />
          </div>
          {/* productdetails */}
          <div className="form-group">
            <label for="">Name of product and its details</label>
            <textarea
              className="form-control"
              rows="7"
              value={productdetails}
              disabled
            />
          </div>

          {/* productsachivecustomerdetails */}
          <div className="form-group">
            <label for="">What does product achieve for customer</label>
            <textarea
              className="form-control"
              rows="7"
              value={productsachivecustomerdetails}
              disabled
            />
          </div>

          {/* what_industry_customer_oprater */}
          <div className="form-group">
            <label for="">In What Industry Does Your Customers Operate?</label>
            <input
              className="form-control"
              value={what_industry_customer_oprater}
              disabled
            />
          </div>

          {/* uniquesolution */}
          <div className="form-group">
            <label for="">Unique about the product/solution</label>
            <textarea
              className="form-control"
              rows="7"
              value={uniquesolution}
              disabled
            />
          </div>
          {/* businesscompetition */}
          <div className="form-group">
            <label htmlFor="Email1">Business competitors</label>
            <textarea
              className="form-control"
              rows="7"
              value={businesscompetition}
              disabled
            />
          </div>

          {/* planningearn */}
          <div className="form-group">
            <label htmlFor="">
              How do earn / plan to earn revenue from customers
            </label>
            <input className="form-control" value={planningearn} disabled />
          </div>

          {/* ispayingcustomer */}
          <div className="form-group">
            <label htmlFor="Email1">
              As of Now Do You Have any Paying Customers?
            </label>
            <br />
            <input className="form-control" value={ispayingcustomer} disabled />
          </div>

          {/* websitename */}
          <div className="form-group">
            <label for="">
              If Your StartUp Has A Website, Enter The URL Below
            </label>
            <input className="form-control" value={websitename} disabled />
          </div>

          {/* how_rank_product */}
          <div className="form-group">
            <label for="">How Would You Rank Your Product?</label>
            <input className="form-control" value={how_rank_product} disabled />
          </div>

          {/* how_old_startup */}
          <div className="form-group">
            <label htmlFor="">How Old Is Your Startup?</label>
            <input className="form-control" value={how_old_startup} disabled />
          </div>
          {/* how_people_in_startup */}
          <div className="form-group">
            <label htmlFor="">How Many People Are There In Your Startup</label>
            <input
              className="form-control"
              value={how_people_in_startup}
              disabled
            />
          </div>
          {/* file */}
          <div className="form-group">
            <label htmlFor="Email1">
              Please Upload a Document Describing Your Employee Details (Name,
              Position, Job Description, Experience, LinkedIn Profile Link)
            </label>
            <input />
          </div>

          {/* who_found_of_startup */}
          <div className="form-group">
            <label htmlFor="">Who Were The Founder Of Your Startup</label>
            <input
              className="form-control"
              value={who_found_of_startup}
              disabled
            />
          </div>

          {/* linkedin */}
          <div className="form-group">
            <label htmlFor="">
              Please Provide Links To Their LinkedIn Profiles If Available
            </label>
            <input className="form-control" value={linkedin} disabled />
          </div>

          {/* describe_birth_startup */}
          <div className="form-group">
            <label htmlFor="">Describe The Birth of Your Startup</label>
            <textarea
              className="form-control"
              rows="7"
              value={describe_birth_startup}
              disabled
            />
          </div>

          {/* how_found_startup */}
          <div className="form-group">
            <label htmlFor="">How Are you Funding Your Startup?</label>
            <textarea
              className="form-control"
              rows="7"
              value={how_found_startup}
              disabled
            />
          </div>

          {/* file2 */}
          <div className="form-group">
            <label for="Email1">
              Please Upload Documents Related To Your Products If Any (Product
              details, Roadmap, Market Analysis,..etc)
            </label>
            <input type="" className="form-control" id="Email1" />
          </div>

          {/* email */}
          <div className="form-group">
            <label for="Email1">Email</label>
            <input className="form-control" value={email} disabled />
          </div>

          {/* tel */}
          <div className="form-group">
            <label htmlFor="">Mobile Phone Number </label>
            <input className="form-control" value={tel} disabled />
          </div>

          {/* work_tel */}
          <div className="form-group">
            <label htmlFor="">Work Phone Number</label>
            <input className="form-control" value={work_tel} disabled />
          </div>

          {/* other */}
          <div className="form-group">
            <label htmlFor="">
              Are you currently part of any other accelerator/incubator?
            </label>
            <input className="form-control" value={other} disabled />
          </div>

          {/* expectations_details */}
          <div className="form-group">
            <label htmlFor="">Describe Your Expectations?</label>
            <textarea
              className="form-control"
              rows="7"
              value={expectations_details}
              disabled
            />
          </div>
        </form>
      </div>
    </div>
  );
};
