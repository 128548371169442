import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../../../style/wrappers/RegisterPage";

export const InternshipVideoUpdate = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true); // Loading state to handle async data fetching

  const { id } = useParams(); // Destructure id from useParams
  const navigate = useNavigate(); // Correct usage of useNavigate
  const [videoData, setVideoData] = useState(null);

  // Fetch the video details on component mount
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await axios.get(`/api/internshipvideo/get/${id}`);
        setName(response.data.name);
        setUrl(response.data.url);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching video:", error);
        setLoading(false);
      }
    };

    fetchVideo();
  }, [id]);

  // Handle form submission
  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      await axios.put(
        `/api/internshipvideo/update/${id}`,
        { name, url },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );
      alert("Updated Internship Video");
      navigate("/Ainternshipvideodisplay");
    } catch (error) {
      console.error("Error updating video:", error);
    }
  };

  // Display a loading message or the form based on the loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Wrapper className="full-page">
        <form className="form" onSubmit={handleUpdate}>
          <h3>Update Internship Video</h3>
          {/* Name */}
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Video Link</label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="form-input"
            />
          </div>
          <button type="submit" className="btn btn-block">
            Update
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
