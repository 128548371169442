import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";

// const newPath = window.location.pathname;
// if (newPath === "/blogs") {
//   fetch(
//     "https://api.countapi.xyz/update/159.223.218.132/272af60f-76ca-40f8-af83-7a26e8c1cdbe?amount=1"
//   );
// }

export const CBlogs = () => {
  const [item, setItem] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "blogs";

  useEffect(() => {
    try {
      axios.get("/api/blogs/get").then((res) => {
        setItem(res.data);
      });
    } catch (err) {
      console.log(err);
    }

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);

  return (
    <div className="bggray">
      <Navbar />
      <div className="mt-5 my-5">
        <div className="">
          <br></br>
          <div className="text-center my-5">
            <h1>Blogs</h1>
          </div>
          {item.map((blogs) => {
            return (
              <section
                className=" py-2 mx-3 mt-3 bg-light shadow rounded-2"
                id="scroll-target"
              >
                <div className="container px-5 my-3">
                  <div className="row gx- justify-content-center text-center border shadow rounded hover-zoom py-2">
                    <div className="col-lg-7 ">
                      <div className="">
                        {/* <img
													className="img-fluid rounded mb-5 mb-lg-3 "
													src={"/blogs/" + items.image}
													alt="..."
 /> */}
                        <img
                          style={{ width: "90%", height: "(90%" }}
                          className="mb-5 rounded shadow hover-zoom"
                          src={"/blogs/" + blogs.image}
                          alt="..."
                        />
                      </div>
                      <h3 className="fw-bolder mb-2">{blogs.name}</h3>
                      <div className="">
                        <h7 className="fw-bolder mb-2">{blogs.uploadername}</h7>
                        <h7 className="fw-bolder mb-2 ml-3 ">{blogs.date}</h7>
                      </div>
                      <h6 className="lead fw-normal text-muted mb-0 text-justify mt-2">
                        {blogs.shortdesc}
                      </h6>
                      <Link to={`/blogs/${blogs._id}`}>
                        <button className="btn btn-primary mt-2 mb-2">
                          More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
              </section>
            );
          })}
        </div>
      </div>
      <Contact />
    </div>
  );
};
