import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";
import Wrapper from "../../style/wrappers/RegisterPage";
import { useNavigate } from "react-router-dom";

export const Blogs = () => {
  const current = new Date();
  const cdate = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const [name, setName] = useState("");
  const [uploadername, setUploadername] = useState("");
  const [date, setDate] = useState(cdate);
  const [shortdesc, setShortdesc] = useState("");
  const [longdesc, setLongdesc] = useState("");
  const [file, setFile] = useState([]);
  const [items, setItems] = useState([]);

  const ImageAdd = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("user"));
    if (items) {
      setItems(items);
      const names = items.details.name;
      setUploadername(names);
    }
  }, []);

  const navi = useNavigate();

  const Submit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("uploadername", uploadername);
    formData.append("date", date);
    formData.append("shortdesc", shortdesc);
    formData.append("longdesc", longdesc);
    formData.append("blogstype", "blogs");
    formData.append("file", file);

    console.log("Form Data:", {
      name,
      uploadername,
      date,
      shortdesc,
      longdesc,
      blogstype: "blogs",
      file,
    });

    setName("");
    setUploadername("");
    setDate("");
    setShortdesc("");
    setLongdesc("");

    axios
      .post("/api/blogs/add", formData, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        alert("Successfully Added Blogs");
        navi("/Ablogsdisplay");
      })
      .catch((err) => {
        console.error("Error in Submit:", err);
      });
  };

  return (
    <div>
      <Navbar />
      <div>
        <Link to={"/Ablogsdisplay"}>
          <div className="" style={{ textAlign: "center", marginTop: "5%" }}>
            <button type="button" className="btn btn-primary">
              Blogs
            </button>
          </div>
        </Link>
      </div>
      {/* FORM */}
      <div>
        <Wrapper className="full-page">
          <form
            className="form"
            onSubmit={Submit}
            encType="multipart/form-data"
          >
            <h3>Add New Blog</h3>

            {/*  */}
            <div className="form-row">
              <label className="form-label">Name</label>
              <input
                type="text"
                name={uploadername}
                value={JSON.parse(localStorage.getItem("user")).details?.name}
                onChange={(e) => setUploadername(e.target.value)}
                className="form-input"
              />
            </div>
            {/* date */}
            <div className="form-row">
              <label className="form-label">Date</label>
              <input
                type="text"
                value={cdate}
                name={cdate}
                onChange={(e) => setDate(e.target.value)}
                className="form-input"
              />
            </div>
            {/*Blog name */}
            <div className="form-row">
              <label className="form-label">Blog Name</label>
              <input
                type="text"
                name={name}
                onChange={(e) => setName(e.target.value)}
                className="form-input"
                required
              />
            </div>
            {/* short desc */}
            <div className="form-row">
              <label className="form-label"> Short Description</label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                value={shortdesc}
                name={shortdesc}
                onChange={(e) => setShortdesc(e.target.value)}
                required
              />
            </div>

            {/* longdesc */}
            <div className="form-row">
              <label className="form-label">Description</label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                value={longdesc}
                name={longdesc}
                onChange={(e) => setLongdesc(e.target.value)}
                required
              />
            </div>

            {/* image */}
            <div className="form-row">
              <label className="form-label">Image</label>
              <input
                type="file"
                multiple
                filename="file"
                onChange={ImageAdd}
                className="form-input"
                required
              />
            </div>
            <button type="submit" className="btn btn-block">
              Submit
            </button>
          </form>
        </Wrapper>
      </div>
    </div>
  );
};
