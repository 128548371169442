import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import emailjs from "@emailjs/browser";

export const EntraForm = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [businessname, setBusinessname] = useState("");
  const [businessaddress, setBusinessaddress] = useState("");
  const [registedbusiness, setRegistedbusiness] = useState("");
  const [businesstype, setBusinesstype] = useState("");
  const [productdetails, setProductdetails] = useState("");
  const [productsachivecustomerdetails, setProductsachivecustomerdetails] =
    useState("");
  const [what_industry_customer_oprater, setWhat_industry_customer_oprater] =
    useState("");
  const [uniquesolution, setUniquesolution] = useState("");
  const [businesscompetition, setBusinesscompetition] = useState("");
  const [planningearn, setPlanningearn] = useState("");
  const [ispayingcustomer, setIspayingcustomer] = useState("");
  const [websitename, setWebsitename] = useState("");
  const [how_rank_product, setHow_rank_product] = useState("");
  const [how_old_startup, setHow_old_startup] = useState("");
  const [how_people_in_startup, setHow_people_in_startup] = useState("");
  const [who_found_of_startup, setWho_found_of_startup] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [describe_birth_startup, setDescribe_birth_startup] = useState("");
  const [how_found_startup, setHow_found_startup] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [work_tel, setWork_tel] = useState("");
  const [other, setOther] = useState("");
  const [expectations_details, setExpectations_details] = useState("");
  const [file, setFile] = useState([]);
  const [adminemail, setAdminemail] = useState([]);

  useEffect(() => {
    try {
      axios.get("/api/entrepreneurshipadmin/get").then((res) => {
        setAdminemail(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const arrytoobject = adminemail.map((u) => u.email);
  const emails = arrytoobject.join(" ");

  const ImageAdd = (e) => {
    setFile(e.target.files[0]);
  };

  const sendForm = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("address", address);
    formData.append("businessname", businessname);
    formData.append("businessaddress", businessaddress);
    formData.append("registedbusiness", registedbusiness);
    formData.append("businesstype", businesstype);
    formData.append("productdetails", productdetails);
    formData.append(
      "productsachivecustomerdetails",
      productsachivecustomerdetails
    );
    formData.append(
      "what_industry_customer_oprater",
      what_industry_customer_oprater
    );
    formData.append("uniquesolution", uniquesolution);
    formData.append("businesscompetition", businesscompetition);
    formData.append("planningearn", planningearn);
    formData.append("ispayingcustomer", ispayingcustomer);
    formData.append("websitename", websitename);
    formData.append("how_rank_product", how_rank_product);
    formData.append("how_old_startup", how_old_startup);
    formData.append("how_people_in_startup", how_people_in_startup);
    formData.append("who_found_of_startup", who_found_of_startup);
    formData.append("linkedin", linkedin);
    formData.append("describe_birth_startup", describe_birth_startup);
    formData.append("how_found_startup", how_found_startup);
    formData.append("email", email);
    formData.append("tel", tel);
    formData.append("work_tel", work_tel);
    formData.append("other", other);
    formData.append("expectations_details", expectations_details);
    formData.append("file", file);

    setName("");
    setAddress("");
    setBusinessname("");
    setBusinessaddress("");
    setRegistedbusiness("");
    setBusinesstype("");
    setProductdetails("");
    setProductsachivecustomerdetails("");
    setWhat_industry_customer_oprater("");
    setUniquesolution("");
    setBusinesscompetition("");
    setPlanningearn("");
    setIspayingcustomer("");
    setWebsitename("");
    setHow_rank_product("");
    setHow_old_startup("");
    setHow_people_in_startup("");
    setWho_found_of_startup("");
    setLinkedin("");
    setDescribe_birth_startup("");
    setHow_found_startup("");
    setEmail("");
    setTel("");
    setWork_tel("");
    setOther("");
    setExpectations_details("");

    axios.post("/api/entrepreneurship/add", formData).then((res) => {
      console.log("send data");
      console.log(formData);
    });
    const templateParams = {
      name: name,
      email: email,
      tel: tel,
      type: "Entrepreneurship",
      object: emails,
    };
    // console.log(templateParams);

    emailjs
      .send(
        "service_sx17i5s",
        "template_ybem4ei",
        templateParams,
        "1OioyNoQgHrcuKdxN"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          alert("Send");
          window.location.reload();
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

	return (
		<div>
			<Navbar />
			{/* form */}
			<div
				className="container border-3 border-primary border-top border-left rounded-3 shadow px-4 mb-5 pb-3 py-4 bg-light "
				style={{ width: "75%", marginTop: "15%", marginLeft: "" }}
			>
				<div className="text-center">
					<h2>SLT Entrepreneurship Studio</h2>
				</div>
				<form className="mt-5" style={{
					width: "95%",
					paddingLeft: "2%",
					paddingRight: "2%",
					marginLeft: "2.5%"
				}} onSubmit={sendForm}>
					{/* name */}
					<div className="form-group">
						<label for="Name">Enter Your Name</label>
						<input
							type="name"
							className="form-control"
							value={name}
							name={name}
							onChange={(e) => setName(e.target.value)}
							id="Name"
							placeholder="Name"
							required
						/>
					</div>
					{/* address */}
					<div className="form-group">
						<label for="">Enter Your Address</label>
						<textarea
							className="form-control"
							value={address}
							name={address}
							onChange={(e) => setAddress(e.target.value)}
							id="exampleFormControlTextarea1"
							rows="2"
							required
						/>
					</div>
					{/* businessname */}
					<div className="form-group">
						<label for="">Enter The Name of Your Business </label>
						<input
							type=""
							className="form-control"
							value={businessname}
							name={businessname}
							onChange={(e) => setBusinessname(e.target.value)}
							id=""
							required
						/>
					</div>
					{/* businessaddress */}
					<div className="form-group">
						<label for="">Enter The Address of Your Business</label>
						<textarea
							className="form-control"
							value={businessaddress}
							name={businessaddress}
							onChange={(e) => setBusinessaddress(e.target.value)}
							id="exampleFormControlTextarea1"
							rows="3"
							required
						/>
					</div>
					{/* registedbusiness */}
					<div className="form-group">
						<label for="">
							Have You Registered Your Startup As a Business?
						</label>
						<br />
						<select
							className="rounded"
							id="registedbusiness"
							value={registedbusiness}
							onChange={(e) => setRegistedbusiness(e.target.value)}
						>
							<option value="" selected disabled hidden>
								Choose here
							</option>
							<option value="Yes">Yes</option>
							<option value="No">No</option>
						</select>
					</div>
					{/* businesstype */}
					<div className="form-group">
						<label for="">What Kind of Business Are You Into? </label>
						<br />
						<select
							className="rounded"
							id="businesstype"
							value={businesstype}
							onChange={(e) => setBusinesstype(e.target.value)}
						>
							<option value="" selected disabled hidden>
								Choose here
							</option>
							<option value="B2B">Business To Business (B2B)</option>
							<option value="B2C">Business To Consumer (B2C)</option>
							<option value="B2B2C">
								Business To Business To Consumer (B2B2C)
							</option>
						</select>
					</div>
					{/* productdetails */}
					<div className="form-group">
						<label for="">Enter the name of your product and its details</label>
						<textarea
							className="form-control"
							rows="7"
							id="exampleFormControlTextarea1"
							value={productdetails}
							name={productdetails}
							onChange={(e) => setProductdetails(e.target.value)}
							required
						/>
					</div>
					{/* productsachivecustomerdetails */}
					<div className="form-group">
						<label for="">
							What does your product achieve for your customer?
						</label>
						<textarea
							className="form-control"
							id="exampleFormControlTextarea1"
							rows="7"
							value={productsachivecustomerdetails}
							name={productsachivecustomerdetails}
							onChange={(e) => setProductsachivecustomerdetails(e.target.value)}
							required
						/>
					</div>
					{/* what_industry_customer_oprater */}
					<div className="form-group">
						<label for="">In What Industry Does Your Customers Operate?</label>
						<input
							type=""
							className="form-control"
							value={what_industry_customer_oprater}
							name={what_industry_customer_oprater}
							onChange={(e) =>
								setWhat_industry_customer_oprater(e.target.value)
							}
							required
						/>
					</div>
					{/* uniquesolution */}
					<div className="form-group">
						<label for="">What is unique about your product/solution?</label>
						<textarea
							className="form-control"
							id="exampleFormControlTextarea1"
							rows="7"
							value={uniquesolution}
							name={uniquesolution}
							onChange={(e) => setUniquesolution(e.target.value)}
							required
						/>
					</div>
					{/* businesscompetition */}
					<div className="form-group">
						<label for="Email1">Who are your competitors?</label>
						<textarea
							className="form-control"
							id="exampleFormControlTextarea1"
							rows="7"
							value={businesscompetition}
							name={businesscompetition}
							onChange={(e) => setBusinesscompetition(e.target.value)}
							required
						/>
					</div>
					{/* planningearn */}
					<div className="form-group">
						<label for="">
							How do you earn / plan to earn revenue from customers?
						</label>
						<input
							type=""
							className="form-control"
							value={planningearn}
							name={planningearn}
							onChange={(e) => setPlanningearn(e.target.value)}
							required
						/>
					</div>
					{/* ispayingcustomer */}
					<div className="form-group">
						<label for="Email1">
							As of Now Do You Have any Paying Customers?
						</label>
						<br />
						<select
							className="rounded border-"
							id="ispayingcustomer"
							value={ispayingcustomer}
							onChange={(e) => setIspayingcustomer(e.target.value)}
						>
							<option value="" selected disabled hidden>
								Choose here
							</option>
							<option value="Yes">Yes</option>
							<option value="No">No</option>
						</select>
					</div>
					{/* websitename */}
					<div className="form-group">
						<label for="">
							If Your StartUp Has A Website, Enter The URL Below
						</label>
						<input
							type=""
							className="form-control"
							value={websitename}
							name={websitename}
							onChange={(e) => setWebsitename(e.target.value)}
							required
						/>
					</div>
					{/* how_rank_product */}
					<div className="form-group">
						<label for="">How Would You Rank Your Product?</label>
						<input
							type=""
							className="form-control"
							value={how_rank_product}
							name={how_rank_product}
							onChange={(e) => setHow_rank_product(e.target.value)}
							required
						/>
					</div>
					{/* how_old_startup */}
					<div className="form-group">
						<label for="">How Old Is Your Startup?</label>
						<input
							type=""
							className="form-control"
							value={how_old_startup}
							name={how_old_startup}
							onChange={(e) => setHow_old_startup(e.target.value)}
							required
						/>
					</div>
					{/* how_people_in_startup */}
					<div className="form-group">
						<label for="">How Many People Are There In Your Startup</label>
						<input
							type=""
							className="form-control"
							value={how_people_in_startup}
							name={how_people_in_startup}
							onChange={(e) => setHow_people_in_startup(e.target.value)}
							required
						/>
					</div>
					{/* file */}
					<div className="form-group">
						<label for="Email1">
							Please Upload a Document Describing Your Employee Details (Name,
							Position, Job Description, Experience, LinkedIn Profile Link)
						</label>
						<input
							type="file"
							multiple
							filename="file"
							onChange={ImageAdd}
							className="form-input"
							required
						/>
					</div>
					{/* who_found_of_startup */}
					<div className="form-group">
						<label for="">Who Were The Founder Of Your Startup</label>
						<input
							type=""
							className="form-control"
							value={who_found_of_startup}
							name={who_found_of_startup}
							onChange={(e) => setWho_found_of_startup(e.target.value)}
							required
						/>
					</div>
					{/* linkedin */}
					<div className="form-group">
						<label for="">
							Please Provide Links To Their LinkedIn Profiles If Available
						</label>
						<input
							type=""
							className="form-control"
							value={linkedin}
							name={linkedin}
							onChange={(e) => setLinkedin(e.target.value)}
							required
						/>
					</div>
					{/* describe_birth_startup */}
					<div className="form-group">
						<label for="">Describe The Birth of Your Startup</label>
						<textarea
							className="form-control"
							id="exampleFormControlTextarea1"
							rows="7"
							value={describe_birth_startup}
							name={describe_birth_startup}
							onChange={(e) => setDescribe_birth_startup(e.target.value)}
							required
						/>
					</div>
					{/* how_found_startup */}
					<div className="form-group">
						<label for="">How Are you Funding Your Startup?</label>
						<textarea
							className="form-control"
							id="exampleFormControlTextarea1"
							rows="7"
							value={how_found_startup}
							name={how_found_startup}
							onChange={(e) => setHow_found_startup(e.target.value)}
							required
						/>
					</div>
					<div className="form-group">
						<label for="Email1">Enter Your Email</label>
						<input
							type="email"
							className="form-control"
							id="Email1"
							placeholder="Description"
							value={email}
							name={email}
							onChange={(e) => setEmail(e.target.value)}
							required
						/>
					</div>
					{/* tel */}
					<div className="form-group">
						<label for="">Mobile Phone Number </label>
						<input
							type=""
							className="form-control"
							value={tel}
							name={tel}
							onChange={(e) => setTel(e.target.value)}
							required
						/>
					</div>
					{/* work_tel */}
					<div className="form-group">
						<label for="">Work Phone Number</label>
						<input
							type=""
							className="form-control"
							value={work_tel}
							name={work_tel}
							onChange={(e) => setWork_tel(e.target.value)}
							required
						/>
					</div>
					{/* other */}
					<div className="form-group">
						<label for="">
							Are you currently part of any other accelerator/incubator?
						</label>
						<input
							type=""
							className="form-control"
							value={other}
							name={other}
							onChange={(e) => setOther(e.target.value)}
							required
						/>
					</div>
					expectations_details
					<div className="form-group">
						<label for="">Describe Your Expectations?</label>
						<textarea
							className="form-control"
							id="exampleFormControlTextarea1"
							rows="7"
							value={expectations_details}
							name={expectations_details}
							onChange={(e) => setExpectations_details(e.target.value)}
							required
						/>
					</div>
					<div>
						<>
							By clicking on the submit button below you are providing consent
							for us to process and store the information that you have provided
							in the above application. We at the Entrepreneurship Studio are
							committed to safeguarding and respecting your privacy and we
							assure that your information is only used to administer your
							account and provide the products and services that you have
							requested from us. We will also be contacting you with our new
							products and service and other content that may be of interest to
							you.
						</>
					</div>
					<div className="text-center">
						<button type="submit" className="btn" style={{marginTop: "2%" }}>
							Submit
						</button>
					</div>
				</form>
			</div>
			<Contact />
		</div>
	);
};
