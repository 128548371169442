import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../../style/css/admin/User.css";
import { Navbar } from "../../../commn/Navbar.js";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";

export const InternshipA = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate(); // Correct useNavigate

  useEffect(() => {
    axios
      .get("/api/internship/get")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []); // Add empty dependency array

  //delete
  const Deleteadmin = (id) => {
    axios
      .delete(`/api/internship/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token, // Use getItem
        },
      })
      .then(() => {
        alert("Deleted Successfully");
        navigate("/Aintra"); // Use navigate
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="dbtn">
          <Link to="/Ainternshipemail">
            <button className="btn btn-primary mt-3">Internship Email</button>
          </Link>
        </div>
        <div className="td container">
          <h3>Internship from Details</h3>
          <table
            className="table shadow"
            style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
          >
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Educational Institute</th>
                <th scope="col">Degree/Course</th>
                <th scope="col">Date</th>
                <th scope="col">Internship Period</th>
                <th scope="col">Email Address</th>
                <th scope="col">Phone</th>
                <th scope="col">Personal Statement</th>
                <th scope="col">CV</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user._id}>
                  <td>{user.name}</td>
                  <td>{user.institute}</td>
                  <td>{user.degree}</td>
                  <td>{user.month}</td>
                  <td>{user.date}</td>
                  <td>{user.email}</td>
                  <td>{user.tel}</td>
                  <td>{user.statment}</td>
                  <td>
                    <form
                      method="get"
                      action={"http://localhost:5000/internship/" + user.image}
                    >
                      <button type="submit">
                        <AiOutlineDownload
                          style={{ width: "30px", height: "30px" }}
                        />
                      </button>
                    </form>
                    {user.image}
                  </td>
                  <td>
                    <button
                      className="btn"
                      onClick={() => Deleteadmin(user._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
