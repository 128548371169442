import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";

export const IndustryDisplay = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("api/industry/get");
        if (Array.isArray(res.data)) {
          setItems(res.data);
        } else {
          console.error("API response is not an array:", res.data);
        }
      } catch (err) {
        console.error("Error fetching industries:", err);
      }
    };

    fetchData();
  }, []);

  const DeleteIndustry = async (id) => {
    try {
      await axios.delete(`api/industry/delete/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      });
      alert("Deleted Industry");
      setItems(items.filter((item) => item._id !== id));
    } catch (err) {
      console.error("Error deleting industry:", err);
    }
  };

  const isAdmin = JSON.parse(localStorage.getItem("user")).isAdmin; // Check if the user is an admin

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        {isAdmin && (
          <div className="" style={{ textAlign: "center" }}>
            <Link to={"/industryadd"}>
              <button type="button" className="btn btn-primary mt-5">
                ADD New Industry
              </button>
            </Link>
          </div>
        )}
        <div className="mt-5">
          <h3>Industry Partnership</h3>
          <table
            className="table shadow"
            style={{ width: "60%", marginLeft: "15%", marginRight: "15%" }}
          >
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Name</th>
                {isAdmin && <th scope="col">URL</th>}{" "}
                {/* Only show URL for admin */}
                {isAdmin && <th scope="col"></th>}
                {isAdmin && <th scope="col"></th>}
              </tr>
            </thead>
            <tbody>
              {items.length > 0 ? (
                items.map((industry) => (
                  <tr key={industry._id}>
                    <td>
                      <img
                        className="rounded"
                        style={{ width: "40%", height: "40%" }}
                        src={"/industry/" + industry.image}
                        alt={industry.name}
                      />
                    </td>
                    <td>
                      <a
                        href={industry.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {industry.name}
                      </a>
                    </td>
                    {isAdmin && <td>{industry.url}</td>}{" "}
                    {/* Only show URL for admin */}
                    {isAdmin && (
                      <>
                        <td>
                          <Link to={`/updateindustry/${industry._id}`}>
                            <button className="btn">Update</button>
                          </Link>
                        </td>
                        <td>
                          <button
                            className="btn"
                            onClick={() => DeleteIndustry(industry._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={isAdmin ? "5" : "3"}>No industries found.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
