import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";

export const BlogsnotaddView = () => {
  const [item, setItem] = useState([]);
  const [image, setImage] = useState();

  useEffect(() => {
    axios.post("/api/blogspdf/filter", { add: "notadd" }).then((res) => {
      setItem(res.data);
      setImage(res.data.image);
    });
  });

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <Link to={"/Ablogsdisplay"}>
          <button type="button" className="btn btn-primary mt-5">
            Blogs
          </button>
        </Link>
        <Link to={"/ABlogpdf"}>
          <button type="button" className="btn btn-primary mt-5">
            ADD New Blogs Document
          </button>
        </Link>
      </div>
      {/* blogspdf  */}

      <div className="mt-5" style={{ textAlign: "center" }}>
        <div>
          <h2>Document Blogs</h2>
        </div>
        <table
          className="table shadow"
          style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
        >
          <thead>
            <tr>
              {/* <th scope="col">Image</th> */}
              <th scope="col">Uploader Name</th>
              <th scope="col">Upload Date</th>
              <th scope="col">Blog Name</th>
              <th scope="col" style={{ width: "25%" }}>
                Short Description
              </th>
              <th scope="col" style={{ width: "25%" }}>
                Description
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {item.map((blogs) => {
              return (
                <tr>
                  {/* <td><><img className='rounded ' style={{width:"100%",height:"1000%"}} src = {'/blogs/' + blogs.image}/></></td> */}
                  <td>{blogs.uploadername}</td>
                  <td>{blogs.date}</td>
                  <td>{blogs.name}</td>
                  <td>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={8}
                      cols-xs-10
                      type="desc"
                      disabled
                    >
                      {blogs.shortdesc}
                    </textarea>
                  </td>
                  <td>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows={8}
                      cols-xs-10
                      cols={20}
                      type="desc"
                      disabled
                    >
                      {blogs.image}
                    </textarea>
                  </td>
                  <td>
                    <Link to={`/Abpatb/${blogs._id}`}>
                      <button>Update</button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
