import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../../style/css/admin/User.css";
import { Navbar } from "../../../commn/Navbar.js";
import { Link, useNavigate } from "react-router-dom";

export const InternshipemailDisplay = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get("/api/internshipadmin/get");
      setData(res.data);
    } catch (err) {
      console.error("Error fetching internship admins:", err);
    }
  };

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      axios
        .delete(`/api/internshipadmin/delete/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("user")).token,
          },
        })
        .then(() => {
          alert("Internship admin deleted successfully");
          fetchData();
        })
        .catch((err) => {
          console.error("Error deleting internship admin:", err);
          alert("Failed to delete internship admin");
        });
    }
  };

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="container">
          <Link to="/Ainternshipadd">
            <button className="btn btn-primary mt-5">
              Add Internship Emails
            </button>
          </Link>
          <Link to="/Ainternship">
            <button className="btn btn-primary mt-5">Intern Details</button>
          </Link>
          <Link to="/Ainternshipvideodisplay">
            <button className="btn btn-primary mt-5">Intern Videos</button>
          </Link>
        </div>
        <div className="td">
          <h3>Internship Emails</h3>
          <table className="table shadow container" style={{ width: "70%" }}>
            <thead>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((Aemail) => (
                <tr key={Aemail._id}>
                  <td>{Aemail.name}</td>
                  <td>{Aemail.email}</td>
                  <td>
                    <Link to={`/AinternshipemailUpdate/${Aemail._id}`}>
                      <button className="btn">Update</button>
                    </Link>
                    <button
                      className="btn"
                      onClick={() => handleDelete(Aemail._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
