import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";

export const News = () => {
  const [items, setItem] = useState([]);

  const navi = new useNavigate();

  useEffect(() => {
    try {
      axios.get("/api/newsevent/get").then((res) => {
        setItem(res.data);
      });
    } catch (err) {
      console.log(err);
    }
    //   console.log('node men concel beg and t')
  });

  //delete user
  const DeleteNews = (id) => {
    axios.delete(`/api/newsevent/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    alert("Deleted News/Events");
    navi("/Anews");
  };

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="mt-5" style={{ textAlign: "center" }}>
          <Link to={"/Anewsadd"}>
            <button type="button" className="btn btn-primary mt-5">
              ADD New News And Events
            </button>
          </Link>
        </div>
        <div className="mt-5">
          <div>
            <h2>News And Event</h2>
          </div>
          <table
            className="table shadow"
            style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
          >
            <thead>
              <tr>
                <th scope="col">Image</th>
                <th scope="col">Uploader Name</th>
                <th scope="col">Upload Date</th>
                <th scope="col">News Name</th>
                <th scope="col" style={{ width: "25%" }}>
                  Short Description
                </th>
                <th scope="col" style={{ width: "25%" }}>
                  Description
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {items.map((news) => {
                return (
                  <tr>
                    {/* <td>
                      <>
                        <img
                          className="rounded "
                          style={{ width: "100%", height: "1000%" }}
                          src={"/newsevent/" + news.image}
                        />
                      </>
                    </td> */}
                    <td>
                      {news.images && news.images.length > 0 ? (
                        <img
                          className="rounded"
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                          }}
                          src={"/newsevent/" + news.images[0]} // Display the first image
                          alt="News"
                        />
                      ) : (
                        <span>No image available</span>
                      )}
                    </td>
                    <td>{news.uploadername}</td>
                    <td>{news.date}</td>
                    <td>{news.name}</td>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={8}
                        cols-xs-10
                        type="desc"
                        disabled
                      >
                        {news.shortdesc}
                      </textarea>
                    </td>
                    <td>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={8}
                        cols-xs-10
                        cols={20}
                        type="desc"
                        disabled
                      >
                        {news.longdesc}
                      </textarea>
                    </td>
                    {/* <td>{innovation.longdesc}</td> */}
                    <td>
                      <Link to={`/updatenews/${news._id}`}>
                        <button className="btn">Update</button>
                      </Link>

                      <button
                        className="btn"
                        onClick={() => {
                          DeleteNews(news._id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
