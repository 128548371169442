// src/components/admin/IntrapreneurshipemailDisplay.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "../../../commn/Navbar.js";
import "../../../style/css/admin/User.css";

export const IntrapreneurshipemailDisplay = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/api/intrapreneurshipadmin/get");
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Delete user
  const deleteAdmin = (id) => {
    axios
      .delete(`/api/intrapreneurshipadmin/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then(() => {
        alert("Intrapreneurshipadmin has been deleted.");
        navigate("/Aintraemail");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Navbar />
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="dbtn">
          <Link to="/Aintraemailadd">
            <button className="btn btn-primary mt-5">
              Add Intrapreneurships Emails
            </button>
          </Link>
          <Link to="/Aintra">
            <button className="btn btn-primary mt-5">Intrapreneurships</button>
          </Link>
        </div>
        <div className="td">
          <h3>Add Intrapreneurship Email</h3>
          <table
            className="table shadow"
            style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
          >
            <thead>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((AIemail) => (
                <tr key={AIemail._id}>
                  <td>{AIemail.name}</td>
                  <td>{AIemail.email}</td>
                  <td>
                    <Link to={`/Aintraemailupdate/${AIemail._id}`}>
                      <button className="btn">Update</button>
                    </Link>
                    <button
                      className="btn"
                      onClick={() => deleteAdmin(AIemail._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
