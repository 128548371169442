import React from "react";
import { Navbar } from "../commn/Navbar";
import image1 from "../image/ima41.png";
import image2 from "../image/ima51.png";
import image3 from "../image/ima61.png";
import image4 from "../image/ima411.png";
import image5 from "../image/ima511.png";
import image6 from "../image/ima611.png";
import "../style/css/client/home.css";
import { About } from "./home/About";
import { Contact } from "./home/Contact";
import { Object } from "./home/Object";
import { Product } from "./home/Product";
import { HomeNews } from "./home/HomeNews";
import Search from "../search/Search";
// import { Search } from '../search/Search';
// import initialDetails from '../search/initialDetails';

export const Home = () => {
  const sl = {
    transition: "ease 1ms",
  };
  return (
    <div>
      <div>
        <section>
          <Navbar className="" />
        </section>
      </div>
      <div>
        <section>
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide d-img"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active em-image" style={sl}>
                <img
                  className="d-block w-100 h-100"
                  src={image1}
                  alt="First slide"
                />
              </div>
              <div className="carousel-item em-image" style={sl}>
                <img
                  className="d-block w-100 h-100"
                  src={image2}
                  alt="Second slide"
                />
              </div>
              <div className="carousel-item em-image" style={sl}>
                <img
                  className="d-block w-100 h-100"
                  src={image3}
                  alt="Third slide"
                />
              </div>
            </div>
          </div>
          {/* for mobile view */}
          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide m-img"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active em-image" style={sl}>
                <img
                  className="d-block w-100 h-100"
                  src={image4}
                  alt="First slide"
                />
              </div>
              <div className="carousel-item em-image" style={sl}>
                <img
                  className="d-block w-100 h-100"
                  src={image5}
                  alt="Second slide"
                />
              </div>
              <div className="carousel-item em-image" style={sl}>
                <img
                  className="d-block w-100 h-100"
                  src={image6}
                  alt="Third slide"
                />
              </div>
            </div>
          </div>
        </section>
        <Object />
        <About />
        <Product />
        <HomeNews />
        <Search />
        <br />
        {/* <Search details={initialDetails}/> */}
        <Contact />
      </div>
    </div>
  );
};
