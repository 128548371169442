import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../../style/css/admin/User.css";
import { Navbar } from "../../../commn/Navbar.js";
import NavLink, { Link, useNavigate } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";

export const IntrapreneurshipA = () => {
  const [data, setData] = useState([]);

  const navi = new useNavigate();

  useEffect(() => {
    try {
      axios.get("/api/intrapreneurship/get").then((res) => {
        setData(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  });
  //delete
  const Deleteadmin = (id) => {
    axios.delete(`/api/intrapreneurship/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(localStorage.get("user")).token,
      },
    });
    alert("Send Message");
    navi("/Aintra");
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="dbtn">
          <Link to="/Aintraemail">
            <button className="btn btn-primary mt-5">
              Intrapreneurships Emails
            </button>
          </Link>
        </div>
        <div className="td container">
          <h3>Intrapreneurship Form Details</h3>
          <table
            className="table shadow"
            style={{ width: "80%", marginLeft: "10%", marginRight: "10%" }}
          >
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Telephone No</th>
                <th scope="col">Idea</th>
                <th scope="col">Type</th>
                <th scope="col">Description</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((user) => {
                return (
                  <tr>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.tel}</td>
                    <td>{user.idea}</td>
                    <td>{user.type}</td>
                    <td>{user.desc}</td>
                    <td>
                      <form
                        method="get"
                        action={"http://localhost:5000/intra/" + user.image}
                      >
                        <button type="submit">
                          <AiOutlineDownload
                            style={{ width: "30px", height: "30px" }}
                          />
                        </button>
                      </form>
                      {user.image}
                    </td>

                    <td>
                      {/* <Link to={`/updateuser/${user._id}`}>
                                    <button className='btn'>Update</button>
                               </Link> */}
                      <button
                        className="btn"
                        onClick={() => {
                          Deleteadmin(user._id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
