import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./contact.css";
// import { ReactDOM } from 'react';

// const newPath = window.location.pathname;
// if (newPath === "/contactus") {
//   fetch(
//     "https://api.countapi.xyz/update/159.223.218.132/b31dc37e-04db-4768-859a-1452b19fde1e?amount=1"
//   );
// }

export const ContactUS = () => {
  const [name, setName] = useState();
  const [message, setMessage] = useState();
  const [tel, setTel] = useState();
  const [email, setEmail] = useState();
  const [admindata, setAdminData] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "contact";

  //get user data
  useEffect(() => {
    try {
      axios.get("/api/contactadmin/get").then((res) => {
        setAdminData(res.data);
      });
    } catch (err) {
      console.log(err);
    }

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);

  //get users
  const arrytoobject = admindata.map((u) => u.email);
  const emails = arrytoobject.join(" ");

  //upload contact data
  const sendEmail = (e) => {
    e.preventDefault();

    //form data
    const templateParams = {
      name: name,
      email: email,
      tel: tel,
      message: message,
      object: emails,
    };

    //send data for emails
    emailjs
      .send(
        "service_sx17i5s",
        "template_rbl49xp",
        templateParams,
        "1OioyNoQgHrcuKdxN"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          alert("Send Message");
          window.location.reload();
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };
  return (
    <div className="bggray">
      <Navbar />
      <div className="my-3 py-5">
        <h1 className="text-center my-5 py-3">Contact US</h1>
        <div className=" bgwhite mx-4 py-5 my-3 rounded ">
          {/* map  */}
          <div className="">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  className="border rounded shadow"
                  style={{
                    marginLeft: "10%",
                    marginRight: "10%",
                    width: "80%",
                  }}
                  height="300"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=slt%20head%20office&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
                <a href="https://fmovies-online.net"></a>
                <br />
              </div>
            </div>
          </div>

          {/* contact us form */}
          <div className="container cform">
            <div
              className="mb-5"
              style={{ marginLeft: "0%", marginRight: "0%", marginTop: "5%" }}
            >
              <div className="border-left border-top border-info shadow rounded-3 bg-light">
                <form className="px-1 mx-3 mt-5 mb-5" onSubmit={sendEmail}>
                  <h2 className="text-center  mb-4">Send Us a Message</h2>
                  <div className="form-group">
                    <label for="exampleInputEmail1"> Name</label>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="emailHelp"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Mobile Number</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setTel(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputPassword1">Message</label>
                    <textarea
                      cols={20}
                      type="text"
                      className="form-control"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  <div align="center">
                    <button type="submit" className="btn btn-primary mt-4 mb-5">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};
