import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import "./indextm.css";

// const newPath = window.location.pathname;
// if (newPath === "/team") {
//   fetch(
//     "https://api.countapi.xyz/update/159.223.218.132/3774bf2b-6b89-49ea-8bc9-c2bb0ef892e0?amount=1"
//   );
// }

export const OurTeam = () => {
  const [item, setItem] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "team";

  useEffect(() => {
    axios.get("/api/team/get").then((res) => {
      setItem(res.data);
    });

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);


  const numAscending = [...item].sort((a, b) => a.no - b.no);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <Navbar />
      <section>
        <div>
          <div className="mt-5 bggray">
            <div className="pt-5">
              <h1 className="mt-5 mb-5 text-center">Our Team</h1>
            </div>
            <div
              className="mb-5"
              style={{ marginLeft: "5%", marginRight: "5%" }}
            >
              <div className="bgwhite rounded-3 shadow">
                <div className="row gx-1 py-5 px-3 ml-4 row-cols- row-cols-md-2 row-cols-lg-3">
                  {numAscending.map((datas) => {
                    return (
                      <div className="row gx-4">
                        <div className="col-lg- mb-5 justify-content-center text-center">
                          <div className="card h-100 shadow border-2 ">
                            <div className="text-center mt-3">
                              <button
                                className="border-0"
                                onClick={() => openInNewTab(datas.url)}
                              >
                                <img
                                  className="rounded-4 shadow"
                                  href={datas.url}
                                  style={{ width: "100px", height: "100px" }}
                                  src={"/team/" + datas.image}
                                  alt="..."
                                />
                              </button>
                            </div>
                            <div className="card-body ">
                              <h4 className="card-title mb-3">{datas.name}</h4>
                              <h6 className="card-title mb-3">
                                {datas.details}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Contact />
          </div>
        </div>
      </section>
    </div>
  );
};
