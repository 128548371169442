import axios from "axios";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext.js";
import Wrapper from "../../style/wrappers/RegisterPage.js";
import Swal from "sweetalert2";

export const Login = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  // if (logout) {
  //   localStorage.removeItem("user");
  //   window.location = "/admin";
  // }

  const { loading, error, dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  const handleClick = async (e) => {
    e.preventDefault();
    if (
      credentials.email === undefined ||
      credentials.password === undefined ||
      credentials.email === "" ||
      credentials.password === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: "Please fill all the details!!!",
      });
    } else {
      // dispatch({ type: 'LOGIN_START' })
      try {
        await axios.post("/api/user/login", credentials).then((res) => {
          if (res.data.isAdmin) {
            // console.log(res.data.details);
            dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
            localStorage.setItem("last_login", Date.now());
            // Toast.fire({
            //   icon: 'success',
            //   title: 'Signed in successfully',
            // })
            navigate("/user");
          } else {
            dispatch({
              type: "LOGIN_FAILURE",
              payload: { message: "You are not allowed!" },
            });
          }
        });
      } catch (err) {
        dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: err.response.data.message || "Wrong email or password!",
        });
      }
    }
  };

  return (
    <div className="login">
      <Wrapper className="full-page">
        <form className="form">
          <h3>Login</h3>
          {/* name */}
          <div className="form-row">
            <label className="form-label">User Email</label>
            <br />
            <input
              type="text"
              placeholder="email"
              id="email"
              onChange={handleChange}
              className="lInput"
            />
          </div>
          <div className="form-row">
            <label className="form-label mr-3">Password</label>
            <br />
            <input
              type="password"
              placeholder="password"
              id="password"
              onChange={handleChange}
              className="lInput"
            />
          </div>
          <button type="submit" onClick={handleClick} className="btn btn-block">
            submit
          </button>
        </form>
      </Wrapper>

      {/* <div className="lContainer">
        <input
          type="text"
          placeholder="name"
          id="name"
          onChange={handleChange}
          className="lInput"
        />
        <br />
        <br />
        <input
          type="password"
          placeholder="password"
          id="password"
          onChange={handleChange}
          className="lInput"
        />
        <br />
        <button disabled={loading} onClick={handleClick} className="lButton">
          Login
        </button>
        {error && <span>{error.message}</span>}
      </div> */}
    </div>
  );
};
