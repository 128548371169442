import React from "react";
import { Link } from "react-router-dom";

function Card({ person }) {
	return (
		<div className=" bg-transparent" style={{ width: "100%" }}>
			{/* <img className="br-100 h3 w3 dib" alt={person.name} src={process.env.PUBLIC_URL + person.imgPath} /> */}
			<div>
				<Link to={person.url}>
					<button type="submit bg-transparent" className="border-0">
						<h6 className="bg-transparent text-primary ">-{person.name}</h6>
					</button>
				</Link>
			</div>
		</div>
	);
}

export default Card;
