import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "../../style/wrappers/RegisterPage";

export const UpdateUniversity = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState(""); // New state for URL
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUniversity = async () => {
      try {
        const res = await axios.get(`/api/university/get/${params.id}`);
        setName(res.data.name);
        setUrl(res.data.url); // Set URL
      } catch (err) {
        console.error("Error fetching university:", err);
      }
    };
    fetchUniversity();
  }, [params.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.token) {
        throw new Error("User not authenticated");
      }

      const data = { name, url }; // Include URL in the data
      await axios.put(`/api/university/update/${params.id}`, data, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      alert("University updated successfully");
      navigate("/Auniversity");
    } catch (err) {
      console.error("Error updating university:", err.message || err);
      if (err.message === "User not authenticated") {
        alert("You must be logged in to update a university");
        navigate("/login"); // Redirect to login page or handle appropriately
      }
    }
  };

  return (
    <div>
      <Wrapper className="full-page">
        <form className="form" onSubmit={handleSubmit}>
          <h3>Update University</h3>
          {/* Name */}
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          {/* URL */}
          <div className="form-row">
            <label className="form-label">URL</label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              className="form-input"
            />
          </div>
          <button type="submit" className="btn btn-block">
            Update
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
