import React, { useEffect, useState } from "react";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import image from "../../../image/entre.png";
import { FcCheckmark } from "react-icons/fc";
import { Form } from "./EntraForm";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import entraimage from "../../../image/entreProgram.png";

// const newPath = window.location.pathname;
// if (newPath === "/entrepreurship") {
//   fetch(
//     "https://api.countapi.xyz/update/159.223.218.132/3928a4af-bea9-4ecf-8e8c-87b8c2b288c2?amount=1"
//   );
// }

export const Entrepeneurship = () => {
  const [neme, setName] = useState();
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "entrepreneurship";

  const navi = new useNavigate();

  useEffect(() => {
    const name = "ss ";
    setName("Entrepreneurship Studio");
    console.log(name);

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);


  return (
    <div className="bggray">
      <Navbar />
      <div className="mt-5 my-5">
        <div className="text-center pt-5">
          <h1 className="pt-5 mt-5 ">Entrepreneurship Studio</h1>
        </div>
        <div className="bgwhite rounded-5 border-2 border-primary border-bottom border-top shadow pt-5 mx-4 pb-4 mt-5">
          <div
            className=""
            style={{ marginLeft: "20%", width: "60%", marginRight: "20%" }}
          >
            <div className="text-center">
              <img src={image} />
            </div>
            <div className="">
              <div className=" mb-5">
                <h2 className="mb-4 mt-5 " style={{ color: "#2007ab" }}>
                  Let’s work together to launch your dream
                </h2>
                <>
                  We are looking for start-ups in Sri Lanka with innovative
                  products that improve people’s lives and set a trend on how
                  people will live in the future.
                </>
                <h2 className="mb-4 mt-5" style={{ color: "#2007ab" }}>
                  Programme
                </h2>
                <>
                  In this programme, the start-ups will receive access to
                  technologies and support in testing the products in real-life
                  scenarios and access to the SLTMobitel business network for
                  further expanding their business.
                </>
              </div>
              <p>Why you should join with us?</p>
              <div>
                <div className="row mb-5 mt-5 row-cols-1  row-cols-md-2 ">
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Co-innovation Support</h3>
                          <h6>
                            Help you to further enhance or redesign your
                            product.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Technology Support</h3>
                          <h6>
                            Get access to SLT-Mobitel business network and
                            technologies.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Business Support</h3>
                          <h6>
                            Facilitate growth opportunities including funding up
                            to LKR One Million (1 Mn) for 10 lucky start-ups via
                            the SLT-Mobitel Business Ventures.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Pilot Support</h3>
                          <h6>
                            Enable access to SLT-Mobitel client pilot projects.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mb-5">
                <h2 className="mb-4 mt-5 " style={{ color: "#2007ab" }}>
                  Solution Verticals
                </h2>
                <>
                  Check out the solution verticals we are interested in and, if
                  you have a dream of launching innovative products or services
                  in those areas, apply for our Entrepreneurship Studio
                  Programme.
                </>
              </div>

              <div>
                <div className="row mb-5 mt-5 row-cols-1  row-cols-md-2 ">
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Smart Enterprise</h3>
                          <h6>
                            Products and services that are used to enable
                            efficient enterprise operational functions.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Smart Industry</h3>
                          <h6>
                            Products and services that are used in
                            digitalization, connecting products, machines and
                            people in various industries.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Smart Living</h3>
                          <h6>
                            Products and services that are used to give people
                            the opportunity to benefit from new ways of living.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Tourism</h3>
                          <h6>
                            Products and services that are used in the travel,
                            tourism and hospitality industry.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Transport & Logistics</h3>
                          <h6>
                            Products and services that are used in the
                            transportation and logistics industry.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="row mb-5 mt-5 row-cols-1  row-cols-md-2 ">
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">AgriTech</h3>
                          <h6>
                            Products and services that are used in agriculture
                            to maximize yields and efficiency
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">EduTech</h3>
                          <h6>
                            Products and services that are facilitating learning
                            and improving educational performance by creating,
                            using and managing appropriate technological
                            processes and resources.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Entertainment</h3>
                          <h6>
                            Products and services that are used in forms of
                            entertainment
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">FinTech</h3>
                          <h6>
                            Products and services that aims to transform
                            traditional financial methods in the delivery of
                            financial services.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-5 ml-2">
                    <div className="col-lg- mb-2">
                      <div className=" h-100 ">
                        <div className="px-3 pt-1"></div>
                        <div className="">
                          <FcCheckmark
                            className="mb-3 rounded-5 border"
                            size={"30"}
                          />
                          <h3 className=" ">Gaming</h3>
                          <h6>
                            Products and services that are used to create a
                            gamification environment.
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="previous-programs-section mt-5">
                <h3 className="text-center">Previous Programs</h3>
                <div className="program-container mt-4" onClick={() => navi('/entreprogram')}>
                  <div className="program-image">
                    <img
                      src={entraimage}
                      alt="2023 Program"
                      className="img-fluid"
                      style={{ borderRadius: '15px', width: '100%', objectFit: 'cover', height: '200px' }}
                    />
                    <div className="program-description mt-3">
                      <h4 className="text-center">2023 Program </h4>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="">
            <Link to="/entraform">
              <button
                className="rounded py-2 px-2"
                style={{ marginLeft: "45%", marginTop:"3%" }}
              >
                Apply
              </button>
            </Link>
          </div>
          {/* <Form/> */}
        </div>
      </div>
      <Contact />
    </div>
  );
};
