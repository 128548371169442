import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";

// const newPath = window.location.pathname;
// if (newPath === "/news") {
//   fetch(
//     "https://api.countapi.xyz/update/159.223.218.132/b10a2d15-d235-48b1-87a2-d354110c40ef?amount=1"
//   );
// }

export const NewsC = () => {
  const [item, setItem] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "news";

  useEffect(() => {
    try {
      axios.get("/api/newsevent/get").then((res) => {
        setItem(res.data);
      });
    } catch (err) {
      console.log(err);
    }

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);

  return (
    <div className="bggray">
      <Navbar />
      <div className="mt-5 my-5">
        <div className="">
          <br></br>
          <div className="text-center my-5">
            <h1>News And Events</h1>
          </div>
          {item.map((news) => {
            const firstImage =
              news.images && news.images.length > 0 ? news.images[0] : ""; // Get the first image
            return (
              <section
                className="py-2 mx-3 mt-3 bg-light shadow rounded-2"
                id="scroll-target"
                key={news._id} // Add a unique key prop
              >
                <div className="container px-5 my-3">
                  <div className="row gx- justify-content-center text-center border shadow rounded hover-zoom py-2">
                    <div className="col-lg-7">
                      <div className="">
                        {firstImage && (
                          <img
                            style={{ width: "100%", height: "auto" }}
                            className="mb-5 rounded shadow hover-zoom"
                            src={`/newsevent/${firstImage}`}
                            alt="news"
                          />
                        )}
                      </div>
                      <h3 className="fw-bolder mb-2">{news.name}</h3>
                      <Link to={`/news/${news._id}`}>
                        <button className="btn btn-primary mt-2 mb-2">
                          Read More
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <hr />
              </section>
            );
          })}
        </div>
      </div>
      <Contact />
    </div>
  );
};
