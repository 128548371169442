import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import NumericInput from "react-numeric-input";
import emailjs from "@emailjs/browser";

const newPath = window.location.pathname;
if (newPath === "/intrapeneurship") {
  fetch(
    "https://api.countapi.xyz/update/159.223.218.132/7f5c917a-0783-49dc-b1fb-f07d2d4a00c4?amount=1"
  );
}

export const Internship = () => {
  const [adminemail, setAdminemail] = useState([]);
  const [name, setName] = useState("");
  const [institute, setInstitute] = useState("");
  const [degree, setDegree] = useState("");
  const [month, setMonth] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [statment, setStatment] = useState("");
  const [file, setFile] = useState([]);

  const current = new Date();
  const tdate = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const ImageAdd = (e) => {
    setFile(e.target.files[0]);
  };

  const navi = new useNavigate();

  useEffect(() => {
    try {
      axios.get("/api/internshipadmin/get").then((res) => {
        setAdminemail(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // console.log(adminemail)
  const arrytoobject = adminemail.map((u) => u.email);
  const emails = arrytoobject.join(" ");
  // console.log(emails);

  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("institute", institute);
    formData.append("degree", degree);
    formData.append("month", month);
    formData.append("date", tdate);
    formData.append("email", email);
    formData.append("tel", tel);
    formData.append("file", file);

    setName("");
    setInstitute("");
    setDegree("");
    setMonth("");
    setDate("");
    setEmail("");
    setTel("");

    axios.post("/api/internship/add", formData).then((res) => {
      console.log("send");
      console.log(formData);
    });
    const templateParams = {
      name: name,
      email: email,
      tel: tel,
      type: "Internship",
      object: emails,
    };
    console.log(templateParams);

    emailjs
      .send(
        "service_sx17i5s",
        "template_ybem4ei",
        templateParams,
        "1OioyNoQgHrcuKdxN"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          alert("Send");
          window.location.reload();
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <div className="bggray">
      <Navbar />
      <div className="mt-5 my-5">
        <div className="text-center pt-5">
          <h1 className="pt-5 mt-5 ">Apply for Internship</h1>
        </div>
        <div
          className="bgwhite rounded-5 border-2 border-primary border-bottom border-top shadow pt-5  pb-4 mt-5"
          style={{ marginLeft: "3%", marginRight: "3%" }}
        >
          <div
            className=""
            style={{ marginLeft: "20%", width: "60%", marginRight: "20%" }}
          >
            <div>
              <div className="text-center">
                <h3 className="mb-4 " style={{ color: "#2007ab" }}>
                  Apply for Your Internship With SLT Digital Lab
                </h3>
                <h5 className="mb-4" style={{ color: "#2007ab" }}>
                  Job Description
                </h5>
              </div>
              Are you a student looking for an Internship in Research and
              Development? Are you looking for an internship that will offer you
              an assignment in one of our running projects and that will
              challenge you with a wide randing variety of work and
              opportunities?
              <br /> <br /> If you are, keep reading…
              <br /> <br /> We pride ourselves on being able to offer interns a
              challenging experience in our R&D initiatives.
              <br /> <br /> In your role as a R&D intern you will be given full
              responsibility and accountability for projects from the beginning
              of your placement and you will have an opportunity to fully
              integrate and actually make a difference. We offer internships in
              different expertise areas within Research & Development (R&D) like
              IoT, AI/ML/DL,5G, Network Sofwarization, programming
              <br /> <br />
              <div className="text-center">
                <h5 className="mb- mt-5" style={{ color: "#2007ab" }}>
                  Qualifications
                </h5>
              </div>
              <br /> <br /> To be successful in this role you need to be
              enrolled at a University for the whole duration of the internship
              preferably in one of the following degrees:
              <br /> <br />
              <div className="ml-5">
                • Electronic/Computer Engineering
                <br />• Computer Science
              </div>
              <br /> <br /> If you are the right person, apply now
            </div>
          </div>

          {/* form */}
          <div
            className="border-3 border-primary border-top border-left rounded-3 shadow my-5 pb-3 py-4 bg-light "
            style={{
              width: "85%",
              paddingLeft: "2%",
              paddingRight: "2%",
              marginLeft: "7.5%",
            }}
          >
            <div className="text-center">
              <h3>Internship Application</h3>
            </div>
            <form
              className="mt-5"
              onSubmit={sendEmail}
              encType="multipart/form-data"
            >
              <div className="form-group">
                <label for="Name">Name</label>
                <input
                  type="name"
                  value={name}
                  name={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  id="Name"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="form-group">
                <label for="Email1">Educational Institute </label>
                <input
                  type=""
                  value={institute}
                  name={institute}
                  onChange={(e) => setInstitute(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <div className="form-group">
                <label for="Email1">Degree/Course</label>
                <input
                  type=""
                  value={degree}
                  name={degree}
                  onChange={(e) => setDegree(e.target.value)}
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group">
                <label for="Email1">Date</label>
                <input
                  type=""
                  value={tdate}
                  name={date}
                  // onChange={(e) => setDate(e.target.value)}
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group">
                <label for="">Internship Period (In Months)</label>
                <br />
                <NumericInput
                  value={month}
                  name={month}
                  onChange={setMonth}
                  style={{ width: "50%" }}
                  className=""
                  required
                />
              </div>

              <div className="form-group">
                <label for="Email1">Email Address</label>
                <input
                  type="email"
                  value={email}
                  name={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group">
                <label for="Email1">Phone</label>
                <input
                  type=""
                  value={tel}
                  name={tel}
                  onChange={(e) => setTel(e.target.value)}
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group">
                <label for="Email1">Personal Statment</label>
                <input
                  type=""
                  value={statment}
                  name={statment}
                  onChange={(e) => setStatment(e.target.value)}
                  className="form-control"
                  required
                />
              </div>

              <div className="form-group">
                <label for="Email1">
                  Any Relevant Attachments In PDF (Max 4MB)
                </label>
                <input
                  type="file"
                  multiple
                  filename="file"
                  onChange={ImageAdd}
                  className="form-input"
                  required
                />
              </div>

              <div className="text-center">
                <button type="submit" className="btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};
