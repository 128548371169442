import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../style/css/admin/User.css";
import { Navbar } from "../../commn/Navbar.js";
import NavLink, { Link, useNavigate } from "react-router-dom";

export const ContactusEmailAdminDisplay = () => {
  const [data, setData] = useState([]);

  const navi = new useNavigate();

  useEffect(() => {
    try {
      axios.get("/api/contactadmin/get").then((res) => {
        setData(res.data);
      });
    } catch (err) {
      console.log(err);
    }
  });
  //delete user
  const Deleteadmin = (id) => {
    axios.delete(`/api/contactadmin/delete/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(localStorage.getItem("user")).token,
      },
    });
    alert("Delete User Email Details");
    navi("/Acontactadmin");
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="mt-5" style={{ textAlign: "center" }}>
        <div className="dbtn">
          <Link to="/Acontactadminadd">
            <button className="btn btn-primary mt-5">Add Contact Emails</button>
          </Link>
        </div>
        <div className="td">
          <table
            className="table shadow"
            style={{ width: "70%", marginLeft: "15%", marginRight: "15%" }}
          >
            <thead>
              <tr>
                <th scope="col">User Name</th>
                <th scope="col">Email</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((ACemail) => {
                return (
                  <tr>
                    <td>{ACemail.name}</td>
                    <td>{ACemail.email}</td>
                    <td>
                      <Link to={`/Acontactadminupdate/${ACemail._id}`}>
                        <button className="btn">Update</button>
                      </Link>
                      <button
                        className="btn"
                        onClick={() => {
                          Deleteadmin(ACemail._id);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
