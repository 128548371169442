import React, { useState } from "react";
import PDF from "./AS.pdf";
import { Document, Page, pdfjs } from "react-pdf";

export const BlogsPDF = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const url = PDF;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div>
      <div className="border mx-auto bg-light row" style={{ width: "90%" }}>
        {/* <div className="col-2 align-self-center">
					<div>
						<button
							type="button"
							disabled={pageNumber <= 1}
							onClick={previousPage}
							className=" rounded-2 border-0 bg-transparent"
							style={{ width: "50%", hieght: "70%" }}
						>
							<IoIosArrowBack size={60} />
						</button>
					</div>
				</div> */}

        <div
          className="border-0 mx-auto text-center col"
          style={{ width: "70%", height: "70%" }}
        >
          <div className="">
            Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
          </div>
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            style={{ width: "50%" }}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>

        {/* <div className="col-2 align-self-center">
					<div>
						<button
							type="button"
							className="rounded-2 border-0 bg-transparent"
							disabled={pageNumber >= numPages}
							onClick={nextPages}
						>
							<IoIosArrowForward size={70} />
						</button>
					</div>
				</div> */}
      </div>
    </div>
  );
};
