import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Wrapper from "../../../style/wrappers/RegisterPage.js";
import { Navbar } from "../../../commn/Navbar.js";

export const IntrapreneurshipemailUpdate = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the existing details of the user to pre-fill the form
    axios
      .get(`/api/intrapreneurshipadmin/get/${id}`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        console.log("Fetched data:", res.data);
        setName(res.data.name);
        setEmail(res.data.email);
      })
      .catch((err) => console.log("Error fetching data:", err));
  }, [id]);

  const handleUpdate = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      await axios.put(
        `/api/intrapreneurshipadmin/update/${id}`,
        { name, email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("user")).token
            }`,
          },
        }
      );
      alert("Updated Intrapreneurship admin email");
      navigate("/Aintraemail");
    } catch (error) {
      console.error("Error updating  email:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <Wrapper className="mt-5">
        <form
          className="form"
          onSubmit={handleUpdate}
          style={{ marginTop: "5%" }}
        >
          <h3>Update Entrepreneurship Email</h3>
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
          </div>
          <div className="form-row">
            <label className="form-label">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
            />
          </div>
          <button type="submit" className="btn btn-block">
            Update
          </button>
        </form>
      </Wrapper>
    </div>
  );
};
