import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Navbar } from "../../../commn/Navbar";
import { Contact } from "../../home/Contact";
import { CBlogsMessage } from "./CBlogsMessage";
// import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./Blogs.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import PDF from "./AS.pdf";

export const CBlogsMore = () => {
  const navi = new useNavigate();

  const [item, setItem] = useState([]);
  const [name, setName] = useState();
  const [date, setDate] = useState();
  const [uploadername, setUploader] = useState();
  const [shortdesc, setDesc] = useState();
  const [longdesc, setLongdesc] = useState();
  const [image, setImage] = useState();
  const [mname, setMName] = useState();
  const [memail, setMemail] = useState();
  const [mblogname, setMblogname] = useState();
  const [filterblogname, setFilterblogname] = useState("");
  const [message, setMessage] = useState();
  const [mwebsite, setMwebsite] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogstype, setBlogstype] = useState();
  const [recordsPerPage] = useState(3);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const params = useParams();

  const id = params.id;

  const current = new Date();
  const cdate = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    await axios.get(`/api/blogs/get/${id}`).then((res) => {
      setName(res.data.name);
      setDate(res.data.date);
      setUploader(res.data.uploadername);
      setDesc(res.data.shortdesc);
      setLongdesc(res.data.longdesc);
      setImage(res.data.image);
      setMblogname(res.data.name);
      setFilterblogname(res.data.name);
      setBlogstype(res.data.blogstype);
      axios
        .post("/api/blogsmessage/blogname", { mblogname: res.data.name })
        .then((res) => {
          setItem(res.data);
          setLoading(false);
        });
    });

    // const pdf = "/blogs/" + longdesc;
  };

  // add message function
  const MessageData = (e) => {
    e.preventDefault();
    const messagedata = {
      mname,
      mwebsite,
      mblogname,
      memail,
      message,
    };

    axios.post("/api/blogsmessage/add", messagedata).then((res) => {
      alert("Posted Comment");
      window.location.reload(false);
    });

    console.log(messagedata);
    window.location.reload();
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  // Records to be displayed on the current page
  const currentRecords = item.slice(indexOfFirstRecord, indexOfLastRecord);

  //calculate no of pages
  const nPages = Math.ceil(item.length / recordsPerPage);

  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  // pdf display functions
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPages() {
    changePage(1);
  }

  // const url = PDF;
  const url = "/blogs/" + longdesc;

  return (
    <div className="bggray">
      <Navbar />
      <div className="mt-5 my-5">
        <div className="text-center mt-5 pt-5">
          <h1 className="fw-bolder">{name}</h1>
          {/* {blogstype} */}
        </div>
        {blogstype === "blogs" ? (
          <section
            className=" py-3 mx-3 mt-5 bg-light shadow rounded-2"
            id="scroll-target"
          >
            <div className="container my-3">
              <div className="row  justify-content-center text-center">
                <div className="col-lg-6">
                  <div className="">
                    <img
                      className=" rounded mb-5 mb-lg-0"
                      style={{ width: "90%", height: "(90%" }}
                      src={"/blogs/" + image}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="row mx-5 mb-3 text-center">
                  <h6 className="fw-normal text-muted mb-0">
                    Publish by : {uploadername}
                  </h6>
                  <h6 className="fw-normal text-muted mb-0 ml-"> {date}</h6>
                </div>
                <div className="row mx-5">
                  <div className="col-md-6 mt-1">
                    <p className=" fw-normal text-muted mb-0 text-justify">
                      {shortdesc}
                    </p>
                  </div>
                  <div className="col-md-6 mt-1">
                    <p className=" fw-normal text-muted mb-0 text-justify">
                      {longdesc}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* comment  */}
            <div>
              <div
                className="border shadow container rounded px-1 py-1 my-5"
                style={{ width: "80%" }}
              >
                <h3 className="text-center">Leave a Reply</h3>
                <form className="mx-2 mt-2" onSubmit={MessageData}>
                  <div className="form-row">
                    <div className="col">
                      <input
                        type="text"
                        value={mname}
                        onChange={(e) => setMName(e.target.value)}
                        className="form-control border-0 border-bottom shadow"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        value={memail}
                        onChange={(e) => setMemail(e.target.value)}
                        className="form-control border-0 border-bottom shadow"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        value={mwebsite}
                        onChange={(e) => setMwebsite(e.target.value)}
                        className="form-control border-0 border-bottom shadow"
                        placeholder="Website"
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <textarea
                      rows={5}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="form-control border-0 border-bottom shadow"
                      id="exampleInputPassword1"
                      placeholder="What's on your mind?"
                      required
                    />
                    RResearch & Developmentesearch & Development{" "}
                  </div>
                  <div className=" text-center mb-2">
                    <button type="submit" className="btn btn-primary">
                      POST COMMMENT
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* show comments */}

            <div
              className="border container rounded shadow"
              style={{ width: "80%" }}
            >
              <nav aria-label="Page navigation ">
                <ul className="pagination justify-content-center mt-5">
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={prevPage}
                      href="#"
                      aria-label="Previous"
                    >
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  {pageNumbers.map((pn) => {
                    <li
                      key={pn}
                      className={`page-item ${
                        currentPage == pn ? "active" : ""
                      }`}
                    >
                      <a
                        onClick={() => setCurrentPage(pn)}
                        className="page-link"
                        href="#"
                      >
                        {pn}
                      </a>
                    </li>;
                  })}
                  <li className="page-item">
                    <a
                      className="page-link"
                      onClick={nextPage}
                      href="#"
                      aria-label="Next"
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </nav>
              {/* data */}
              {currentRecords &&
                currentRecords.map((datas) => {
                  return (
                    <div
                      className="container my-4 border-bottom"
                      style={{ width: "75%" }}
                    >
                      <div
                        className="row"
                        style={{ pageIndex: "0", pageSize: "2" }}
                      >
                        <h7 className="col-lg-3">{datas.mname}</h7>
                        <h7 className="col-lg-3">date</h7>
                      </div>
                      <hr />
                      <div className="row">
                        <textarea
                          rows={3}
                          className="form-control border-0 border-bottom mb-1 "
                        >
                          {datas.message}
                        </textarea>
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>
        ) : (
          <div className="border mx-auto bg-light row" style={{ width: "90%" }}>
            <div className="col-2 align-self-center">
              <div>
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  className=" rounded-2 border-0 bg-transparent"
                  style={{ width: "50%", hieght: "70%" }}
                >
                  <IoIosArrowBack size={60} />
                </button>
              </div>
            </div>

            <div
              className="border-0 mx-auto text-center col"
              style={{ width: "70%", height: "70%" }}
            >
              <div className="">
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </div>
              <Document
                file={url}
                onLoadSuccess={onDocumentLoadSuccess}
                style={{ width: "50%" }}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </div>

            <div className="col-2 align-self-center">
              <div>
                <button
                  type="button"
                  className="rounded-2 border-0 bg-transparent"
                  disabled={pageNumber >= numPages}
                  onClick={nextPages}
                >
                  <IoIosArrowForward size={70} />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Contact />
    </div>
  );
};
