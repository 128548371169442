import React, { useState } from "react";
import axios from "axios";
import Wrapper from "../../style/wrappers/RegisterPage";
import { Navbar } from "../../commn/Navbar";
import { Link, useNavigate } from "react-router-dom";

const ContactusEmailAdmin = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const navi = new useNavigate();

  const Submit = (e) => {
    e.preventDefault();

    const newadmin = {
      name,
      email,
    };
    axios
      .post("/api/contactadmin/add", newadmin, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        alert("Succsessfully Added");
        navi("/Acontactadmin");
      });
  };

  return (
    <div>
      <Navbar />
      <div className="mt-3" style={{ textAlign: "center" }}>
        <Link to={"/Acontactadmin"}>
          <button type="button" className="btn btn-primary mt-5">
            Contact Email
          </button>
        </Link>
      </div>
      <Wrapper className="">
        <form className="form" onSubmit={Submit}>
          <h3>Add Contact Email</h3>
          {/* name */}
          <div className="form-row">
            <label className="form-label">Name</label>
            <input
              type={name}
              value={name}
              name={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
              required
            />
            {/* email */}
          </div>
          <div className="form-row">
            <label className="form-label">Email</label>
            <input
              type={email}
              id="emailAddress"
              pattern=".+@gmail\.com"
              size="30"
              value={email}
              name={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              required
            />
          </div>
          <button type="submit" className="btn btn-block">
            submit
          </button>
        </form>
      </Wrapper>
    </div>
  );
};

export default ContactusEmailAdmin;
