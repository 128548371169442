import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "../../commn/Navbar";
import Wrapper from "../../style/wrappers/RegisterPage";

export const AddUniversity = () => {
  const [name, setName] = useState("");
  const [file, setFile] = useState([]);
  const [url, setUrl] = useState(""); // New state for URL

  const ImageAdd = (e) => {
    setFile(e.target.files[0]);
  };

  const navigate = useNavigate();

  const Submit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("file", file);
    formData.append("url", url);

    axios
      .post("api/university/add", formData, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((res) => {
        alert("Successfully Added");
        navigate("/Auniversity");
      });
  };

  return (
    <div>
      <Navbar />
      <div>
        <Link to={"/Auniversity"}>
          <div className="" style={{ textAlign: "center" }}>
            <button type="button" className="btn btn-primary mt-3">
              University
            </button>
          </div>
        </Link>
      </div>
      {/* FORM */}
      <div>
        <Wrapper className="">
          <form
            className="form"
            onSubmit={Submit}
            encType="multipart/form-data"
          >
            <h3>Add New University</h3>
            {/* Name */}
            <div className="form-row">
              <label className="form-label">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-input"
              />
            </div>
            {/* Image */}
            <div className="form-row">
              <label className="form-label">Image</label>
              <input
                type="file"
                filename="file"
                onChange={ImageAdd}
                className="form-input"
              />
            </div>
            {/* URL */}
            <div className="form-row">
              <label className="form-label">URL</label>
              <input
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="form-input"
              />
            </div>
            <button type="submit" className="btn btn-block">
              Submit
            </button>
          </form>
        </Wrapper>
      </div>
    </div>
  );
};
