import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navbar } from "../../commn/Navbar";
import { Contact } from "../home/Contact";

// const newPath = window.location.pathname;
// if (newPath === "/university") {
//   fetch(
//     "https://api.countapi.xyz/update/159.223.218.132/57626701-9204-442d-98c8-818cb8b3e321?amount=1"
//   );
// }

export const University = () => {
  const [item, setItem] = useState([]);
  const [viewCount, setViewCount] = useState(0);
  const [hasIncremented, setHasIncremented] = useState(false);
  const page = "university";

  useEffect(() => {
    axios.get("/api/university/get").then((res) => {
      setItem(res.data);
    });

    const fetchPageViews = async () => {
      try {
        const response = await fetch(`/api/pageView/${page}`);
        const data = await response.json();
        setViewCount(data.count);
      } catch (error) {
        console.error("Error fetching page view count:", error);
      }
    };

    const incrementPageView = async () => {
      try {
        await fetch(`/api/pageView/${page}/increment`, {
          method: 'POST',
        });
        setHasIncremented(true);
      } catch (error) {
        console.error("Error incrementing page view count:", error);
      }
    };

    if (!hasIncremented) {
      fetchPageViews();
      incrementPageView();
    } else {
      fetchPageViews();
    }
  }, [page, hasIncremented]);

  return (
    <div className="bggray">
      <Navbar />
      <div>
        <section className="pt-5 mt-5">
          <div className="container mb-5 my-5">
            <div className="row gx-5 justify-content-center">
              <div className="col-lg-8 col-xl-6">
                <div className="text-center">
                  <h1>University Partnership</h1>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <div className="bgwhite rounded-3 shadow">
                <div className="row gx-1 py-5 px-3 ml-4 row-cols- row-cols-md-2 row-cols-lg-4">
                  {item.map((datas) => {
                    return (
                      <div className="row gx-4" key={datas._id}>
                        <div className="col-lg- mb-5 justify-content-center text-center">
                          <div className="card h-100 shadow border-2">
                            <div className="text-center mt-3">
                              <a
                                href={datas.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  className="rounded-4 shadow"
                                  style={{ width: "100px", height: "100px" }}
                                  src={"/university/" + datas.image}
                                  alt={datas.name}
                                />
                              </a>
                            </div>
                            <div className="card-body">
                              <h5 className="card-title mb-3">
                                <a
                                  className="text-decoration-none link-dark"
                                  href={datas.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {datas.name}
                                </a>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Contact />
      </div>
    </div>
  );
};
