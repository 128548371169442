import React, { useEffect, useState } from "react";
import logo from "../image/logo.jpg";
import "./Navbar.css";
import "./Navbar.css";
import { NavLink, useNavigate, Link } from "react-router-dom";
import Search from "../search/Search";
import $ from "jquery";

export const Test = () => {};

export const Navbar = () => {
  const [item, setItem] = useState([]);
  const [idata, setIdata] = useState([]);

  const [mObject, setObject] = useState("");

  function logout() {
    localStorage.removeItem("user");
    window.location = "/admin";
  }

  const currentLocation = window.location.href;
  const newPathname = window.location.pathname;

  const menuItem = document.querySelectorAll("li a");
  const getcId = document.querySelectorAll("li");
  const contactItem = document.getElementById("idcontact");
  const newsItem = document.getElementById("idnews");
  const programItem = document.getElementById("idprogram");
  const productItem = document.getElementById("idproduct");
  const studioItem = document.getElementById("idstudio");
  const homeItem = document.getElementById("idhome");

  const sId = document.getElementById("navbarDropdown ids");
  const proId = document.getElementById("navbarDropdown idpro");
  const cpId = document.getElementById("navbarDropdown idcp");
  const nwsId = document.getElementById("navbarDropdown idnws");
  const cusId = document.getElementById("navbarDropdown idcus");
  const subHomeId = document.getElementById("subhomeid");

  const menusLength = getcId.length;
  const menuLength = menuItem.length;
  for (let i = 0; i < menuLength; i++) {
    if (menuItem[i].href === currentLocation) {
      menuItem[i].className = "current-tab dropdown-item";
    }
  }
  for (let y = 0; y < menusLength; y++) {
    if (newPathname === "/contactus") {
      contactItem.className = "nav-item nav-area dropdown home-active";
      cusId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/team") {
      contactItem.className = "nav-item nav-area dropdown home-active";
      cusId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/news") {
      newsItem.className = "nav-item nav-area dropdown home-active";
      nwsId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/blogs") {
      newsItem.className = "nav-item nav-area dropdown home-active";
      nwsId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/university") {
      programItem.className = "nav-item nav-area dropdown home-active";
      cpId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/industry") {
      programItem.className = "nav-item nav-area dropdown home-active";
      cpId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/ongoing") {
      programItem.className = "nav-item nav-area dropdown home-active";
      cpId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/innovation") {
      productItem.className = "nav-item nav-area dropdown home-active";
      proId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/intrapeneurship") {
      studioItem.className = "nav-item nav-area dropdown home-active";
      sId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/entrepreurship") {
      studioItem.className = "nav-item nav-area dropdown home-active";
      sId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (newPathname === "/internship") {
      studioItem.className = "nav-item nav-area dropdown home-active";
      sId.className = "nav-link nav-decor dropdown-toggle highlight-active";
    } else if (currentLocation === "") {
      homeItem.className = "nav-item nav-area home-active";
    }
  }

  return (
    <div>
      <>
        {localStorage.getItem("user") === "null" ||
        localStorage.getItem("user") === null ||
        localStorage.getItem("user") === "" ? (
          <>
            {/* client navigation bar */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <a className="navbar-brand navbar-imge" href="/">
                <img
                  src={logo}
                  width="180"
                  height="50"
                  className="d-inline-block align-top"
                  alt=""
                />
              </a>
              <div className="ml-5 pl-5"></div>

              <div
                className="collapse navbar-collapse navbar-align"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto fw-bold nav-sty" id="navba">
                  <li className="nav-item nav-area" id="idhome">
                    <a className="nav-link nav-decor" id="subhomeid" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item nav-area dropdown" id="idstudio">
                    <a
                      className="nav-link nav-decor dropdown-toggle"
                      href="#"
                      id="navbarDropdown ids"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Studios
                    </a>
                    <div
                      className="dropdown-menu sub-navstyle"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/intrapeneurship">
                        Intrapreneurship Studio
                      </a>
                      <a className="dropdown-item" href="/entrepreurship">
                        Entrepreneurship Studio
                      </a>
                      {/* <a className="dropdown-item" href="/internship">Internship Studio</a> */}
                      <a className="dropdown-item" href="/internshipform">
                        Internship Studio
                      </a>
                    </div>
                  </li>
                  <li className="nav-item nav-area dropdown" id="idproduct">
                    <a
                      className="nav-link nav-decor dropdown-toggle"
                      href="#"
                      id="navbarDropdown idpro"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Products and Solutions
                    </a>
                    <div
                      className="dropdown-menu sub-navstyle"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/innovation">
                        Innovation
                      </a>
                      <a
                        className="dropdown-item"
                        href="https://projects.sltdigitallab.lk/login?ref=%2Fprojects"
                      >
                        Managment Console
                      </a>
                      <a
                        className="dropdown-item"
                        href="https://devcloud.sltdigitallab.lk/site/login"
                      >
                        Developer Cloud
                      </a>
                    </div>
                  </li>
                  <li className="nav-item nav-area dropdown" id="idprogram">
                    <a
                      className="nav-link nav-decor dropdown-toggle"
                      href="#"
                      id="navbarDropdown idcp"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Collaboration and Programms
                    </a>
                    <div
                      className="dropdown-menu sub-navstyle"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/university">
                        University Partnership
                      </a>
                      <a className="dropdown-item" href="/industry">
                        Industry Partnership
                      </a>
                      <a className="dropdown-item" href="/ongoing">
                        Ongoing Programms
                      </a>
                    </div>
                  </li>
                  <li className="nav-item nav-area dropdown" id="idnews">
                    <a
                      className="nav-link nav-decor dropdown-toggle"
                      href="#"
                      id="navbarDropdown idnws"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Latest News
                    </a>
                    <div
                      className="dropdown-menu sub-navstyle"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/news">
                        News/Event
                      </a>
                      <a className="dropdown-item" href="/blogs">
                        Blogs
                      </a>
                    </div>
                  </li>
                  <li className="nav-item nav-area dropdown" id="idcontact">
                    <a
                      className="nav-link nav-decor dropdown-toggle"
                      href="#"
                      id="navbarDropdown idcus"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Contact US
                    </a>
                    <div
                      className="dropdown-menu sub-navstyle"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/contactus">
                        Contact US
                      </a>
                      <a className="dropdown-item" href="/team">
                        Our Team
                      </a>
                    </div>
                  </li>
                </ul>
                <Search />
                {/* <form className="d-flex nav-search-align n-search">
                    </form> */}
              </div>
              <Search />
            </nav>
          </>
        ) : (
          // admin navigation bar
          <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-left">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className="collapse navbar-collapse ml-5"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item">
                    <a className="nav-link" href="/user">
                      Users
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Studios
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/Aintraemail">
                        Intrapreneurship Studio
                      </a>
                      <a className="dropdown-item" href="/Aentraemail">
                        Entrepreneurship Studio
                      </a>
                      <a className="dropdown-item" href="/Ainternshipemail">
                        Internship
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/Ainnovation">
                      Innovation
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Colabaration and Programms
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/Auniversity">
                        University Partnership
                      </a>
                      <a className="dropdown-item" href="/Aindustry">
                        Industry Partnership
                      </a>
                      <a className="dropdown-item" href="/Aongoing">
                        Ongoing Partnership
                      </a>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      News and Events
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/Anews">
                        News And Events
                      </a>
                      <a className="dropdown-item" href="/Ablogsdisplay">
                        Blogs
                      </a>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      ContactUS
                    </a>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <a className="dropdown-item" href="/Acontactadmin">
                        Contact US
                      </a>
                      <a className="dropdown-item" href="/Ateam">
                        Our Team
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="">
                <h6>
                  User Name :{" "}
                  {JSON.parse(localStorage.getItem("user")).details?.name}
                </h6>
                <h6>
                  Email :{" "}
                  {JSON.parse(localStorage.getItem("user")).details?.email}
                </h6>
                <div className="text-center">
                  <span className="btn btn-outline-dark" onClick={logout}>
                    <i className="fa fa-sign-in me-1"></i> Logout
                  </span>
                </div>
              </div>
            </nav>
          </>
        )}
      </>
    </div>
  );
};

//  function makeactive(e) {
//    var element = e.target;
//     console.log(element);
//    element.classList.add("d-click");
//     console.log(element.classList);
//  }

// $(document).on('click', 'ul li a', function(){
//   $(this).addClass('active').siblings().removeClass('deactive')
// })

//  console.log(menuItem);
//  console.log(getcId);
//  if (newLocation === "/team"){
//  const mObject = window.location.href;
//  const menuItems = document.querySelectorAll('li p');
//  const menusLength = menuItems.length;
//  for(let i=0; i < menusLength; i++){
//    if(menuItems[i].href === mObject){
//      menuItems[i].className = "active"
//    }
//  }
// }
//  setObject(mObject)
//  console.log(Object)
//  console.log(currentLocation)

//  if(menusItem === newLocation){
//   getcId.className = "nav-link nav-decor dropdown-toggle test"
//   console.log("test")
// }

//  if (newLocation === "/team"){
//   getcId.className = "amila"
//    const menuItems = window.location.href;
//    menuItems.className = "active"
//  }

//  const subLocation = window.location.href;
//  console.log(subLocation);

//  var btnContainer = document.getElementById("navba");
//  var btns = btnContainer.getElementsByClassName("btn");

// for (var i = 0; i < btns.length; i++) {
//   btns[i].addEventListener('click', function () {
//     var current = document.getElementsByClassName("active");
//     current[0].className = current[0].className.replace(" active");
//     this.className += " active";
//   })
// }

//  const activePage = window.location.pathname;
//  const navLinks = document.querySelectorAll('nav ul li a').forEach(link => {
//    if(link.href.includes(`${activePage}`)){
//      link.classList.add('active');
//      console.log(`${activePage}`);
//    }
//  })

// document.querySelectorAll('.nav-link').forEach(link => {
//   if(link.href === window.location.href){
//     link.setAttribute('aria-current','page')
//   }
// })

// let navlinks = document.querySelectorAll(".nav-link")
// navlinks.forEach(a=>{
//   a.addEventListener(a=>a.classList.remove('active'))
//   this.classList.add('active')
// })
