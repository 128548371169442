import React, { useState } from "react";
import Scroll from "./Scroll";
import SearchList from "./SearchList";
import "./Search.css";

function Search() {
  const [searchField, setSearchField] = useState("");
  const [searchShow, setSearchShow] = useState(false);

  const initialDetails = [
    {
      id: 1,
      name: "Innovation",
      url: "/innovation",
    },
    {
      id: 2,
      name: "University Partnership",
      url: "/university",
    },
    {
      id: 3,
      name: "Industry Partnership",
      url: "/industry",
    },
    {
      id: 4,
      name: "Ongoing Partnership",
      url: "/ongoing",
    },
    {
      id: 5,
      name: "News And Events",
      url: "/news",
    },
    {
      id: 6,
      name: "Blogs",
      url: "/blogs",
    },
    {
      id: 7,
      name: "Contact US",
      url: "/contactus",
    },
    {
      id: 8,
      name: "Our Team",
      url: "/team",
    },
    {
      id: 9,
      name: "Intrapreneurship Studio",
      url: "/intrapeneurship",
    },
    {
      id: 10,
      name: "Entrepreneurship Studio",
      url: "/entrepreurship",
    },
    {
      id: 11,
      name: "Internship Studio",
      url: "/internship",
    },
  ];

  const filteredPersons = initialDetails.filter((person) => {
    return person.name.toLowerCase().includes(searchField.toLowerCase());
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
    if (e.target.value === "") {
      setSearchShow(false);
    } else {
      setSearchShow(true);
    }
  };

  function searchList() {
    if (searchShow) {
      return (
        <Scroll className="bg-transparent" style={{ width: "100%" }}>
          <SearchList
            className="bg-transparent"
            filteredPersons={filteredPersons}
          />
        </Scroll>
      );
    }
  }

  return (
    <section className=" border-1 dropz bg-transparent search-mobile">
      <div className="" style={{ width: "100%", marginTop: "10%" }}>
        <input
          className="form-control me-2"
          type="search"
          placeholder="Search"
          onChange={handleChange}
        />
      </div>
      {searchList()}
    </section>
  );
}

export default Search;
