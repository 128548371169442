import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

export const CBlogsMessage = () => {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  // 	function Items({ currentItems }) {
  //   return (
  //     <>
  //       {currentItems &&
  //         currentItems.map((item) => (
  //           <div>
  //             <h3>Item #{item}</h3>
  //           </div>
  //         ))}
  //     </>
  //   );
  // 	}

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const page = 1;

  useEffect(() => {
    const endOffset = itemOffset + page;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    axios.get(`/api/blogs/get/`).then((res) => {
      setCurrentItems(res.data.slice(itemOffset, endOffset));
    });
    setPageCount(Math.ceil(items.length / page));
  }, [itemOffset, page]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * page) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div>
      <>
        {currentItems &&
          currentItems.map((item) => (
            <div>
              <h3>Item #{item.name}</h3>
            </div>
          ))}
      </>
      {/* <ReactPaginate
				// breakLabel="..."
				previousLabel="< previous"

				nextLabel="next >"
				onPageChange={handlePageClick}
				pageRangeDisplayed={5}
				pageCount={pageCount}
			/> */}
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        // style={{height:"2%",innerWidth:"10%"}}
      />
    </div>
  );
};
