import React from "react";
import "./Object.css";

export const About = () => {
  return (
    <div className="bg-dark">
      <div className="py-1 ">
        <div className="container px-5 my-5">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-10 col-xl-7">
              <div className="text-center">
                <div className="fs-4 mb-5 ">
                  <h2 className="text-white h-box">About TheEmbryo</h2>
                  <h5 className="text-white fst-italic text-justify p-box">
                    Research and development (R&D) include activities that
                    companies undertake to innovate and introduce new products
                    and services. It is often the first stage in the development
                    process. The goal is typically to take new products and
                    services to market and add to the company’s bottom line.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
